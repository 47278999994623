import React from 'react';
import PropTypes from 'prop-types';
import snakeCase from 'lodash/snakeCase';
import { Tabs, Center, Text } from '@chakra-ui/react';
import { Tag } from '@/components/ui/tag';

const propTypes = {
  activeTabIndex: PropTypes.number,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      isDisabled: PropTypes.bool,
      label: PropTypes.string,
    })
  ),
};

const TabListComponent = ({ tabList, activeTabIndex = 0 }) => {
  return (
    <Tabs.List borderBottom="0px solid transparent">
      {tabList.map((tab, idx) => {
        return (
          <Tabs.Trigger
            value={tab.value}
            key={tab.label}
            _selected={{ color: 'black', opacity:"1" }}
            color={idx !== activeTabIndex && '#828282'}
            index={`tab-${snakeCase(tab.label)}`}
            disabled={tab.isDisabled}
            pr="0px"
            _focus={{ boxShadow: 'none', outline: 'none' }}
            _before={{ display: 'none' }}
          >
            <Tag
              minWidth="24px"
              minHeight="24px"
              bg={idx === activeTabIndex ? '#3a44b0' : 'white'}
              color={idx === activeTabIndex ? "white" : "black"}
              // color={
              //   idx === activeTabIndex
              //     ? invertColor(get(theme, 'colors.actionSecondary.default', ''), true)
              //     : '#00000040'
              // }
              borderWidth="1px"
              borderColor="#00000040"
              borderRadius="full"
              lineHeight="10px"
              fontSize="16px"
            >
              <Center>{idx + 1}</Center>
            </Tag>
            <Text fontWeight="500" ml="5px">
              {tab.label}
            </Text>
            {idx !== tabList.length - 1 && <hr style={{ width: '60px', marginLeft: '10px' }} />}
          </Tabs.Trigger>
        );
      })}
    </Tabs.List>
  );
};

TabListComponent.propTypes = propTypes;

export default TabListComponent;
