import React from 'react';
import PropTypes from 'prop-types';
import { VStack } from '@chakra-ui/react';
import KPISummary from './KPISummary';
import moment from 'moment';

const KPISummaryList = ({ articles = [] }) => {
  let currentDate = '';
  return (
    <VStack direction={['column', 'row']} gap="5" align="left">
      {articles.map(article => {
        // Display a date box for every month.
        const date = moment(article.published_date).format('MMMM YYYY');
        let displayDate = null;
        if (date !== currentDate) {
          currentDate = date;
          displayDate = currentDate;
        }
        return <KPISummary article={article} date={displayDate} />;
      })}
    </VStack>
  );
};

KPISummaryList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
};

export default KPISummaryList;
