import React from 'react';
import PropTypes from 'prop-types';
import { Box, Image, Heading, Text, HStack } from '@chakra-ui/react';
import { Link } from 'react-router';
import moment from 'moment';
import { truncate } from 'lodash';

const KPISummary = ({ article = {}, date = null }) => {
  return (
    <Box>
      {date && (
        <Heading as="h5" size="md" mt="4" mb="6">
          {date}
        </Heading>
      )}
      <HStack h="180px" gap={0}>
        <Box w="280px">
          <Image borderRadius="10px 0px 0px 10px" src={article.hero_url} h="180px" w="280px" />
        </Box>
        <Box borderRadius="0px 10px 10px 0px" w="800px" h="180px" p="25px" borderTop="0px" background="neutral.9">
          <Heading mb="2" as="h5" size="md">
            {truncate(article.title, { length: 25 })}
          </Heading>
          <Heading as="h5" size="sm">
            {moment(article.published_date).format('MMMM YYYY')}
          </Heading>
          <Text color="neutral.4" fontSize="lg">
            {truncate(article.hook, { length: 200 })}
          </Text>
          <Box mt="5">
            <Link to={`/kpi/${article.region}/${article.slug}`}>
              <Text color="secondary.900" fontSize="md">
                Continue reading
              </Text>
            </Link>
          </Box>
        </Box>
      </HStack>
    </Box>
  );
};

KPISummary.propTypes = {
  article: PropTypes.instanceOf(Object),
  date: PropTypes.string,
};

export default KPISummary;
