import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { Box, IconButton } from '@chakra-ui/react';
import { FaEllipsisV } from 'react-icons/fa';

const computedStyle = getComputedStyle(document.body);

function LoadsChart(props) {
  const { orders } = props;

  const labels = [];
  const expiringLoads = [];
  const yearLater = moment().add(1, 'years');
  for (let date = moment(); date.isBefore(yearLater); date.add(1, 'months')) {
    labels.push(date.format('MMM YYYY'));
    let sum = 0;
    orders.forEach(order => {
      const usedByDate = moment(order.earliest_used_by_date, 'YYYY-MM-DD');

      if (usedByDate && date.format('MMM YYYY') === usedByDate.format('MMM YYYY')) {
        sum += 1;
      }
    });
    expiringLoads.push(sum);
  }

  const data = {
    labels,
    datasets: [
      {
        label: '# of loads expiring',
        backgroundColor: context => {
          const { ctx } = context.chart;
          const gradient = ctx.createLinearGradient(0, 0, 0, 261);
          gradient.addColorStop(0, computedStyle.getPropertyValue('--chakra-colors-chartGradient-0') || '#0233B9');
          gradient.addColorStop(1, computedStyle.getPropertyValue('--chakra-colors-chartGradient-100') || '#0065FF');
          return gradient;
        },
        data: expiringLoads,
      },
    ],
  };
  const options = {
    legend: {
      position: 'top',
      align: 'start',
      labels: {
        padding: 12,
        margin: 20,
        borderRadius: Number.MAX_VALUE,
        usePointStyle: true,
        lineWidth: 20,
        pointStyle: 'circle',
        font: {
          size: 14,
          family: 'Roboto',
          weight: 400,
          lineHeight: 32,
        },
        color: '#374151',
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 0,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Expiry Month',
            fontSize: 14,
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            fontWeight: 700,
            color: '#374151',
          },
          gridLines: {
            display: false,
          },
          ticks: {
            callback: (value, index, values) => {
              return value.split(' ');
            },
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            borderDash: [3],
          },
          ticks: {
            beginAtZero: true,
            min: 0,
            stepSize: 1,
          },
          scaleLabel: {
            display: true,
            labelString: 'Number of Loads',
            fontSize: 14,
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            fontWeight: 700,
            color: '#374151',
          },
        },
      ],
    },
  };

  const plugins = [
    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height = this.height + 40;
        };
      },
    },
  ];

  return (
    <Box
      backgroundColor="white"
      position="relative"
      height="460px"
      width="100%"
      maxWidth="833px"
      padding="20px 20px"
      boxShadow="md"
      borderRadius="6px"
    >
      <IconButton position="absolute" variant="ghost" color="gray.400" right="5">
        <FaEllipsisV />
      </IconButton>
      <Bar data={data} options={options} plugins={plugins} />
    </Box>
  );
}

LoadsChart.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      earliest_used_by_date: PropTypes.string,
    })
  ),
};

export default LoadsChart;
