import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, HStack, Text } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const PaginationButton = ({ buttonChild, isCurrent, onClick, ...rest }) => {
  return (
    <Button
      height="30px"
      width="30px"
      bgColor="white"
      color="current"
      borderRadius={0}
      borderColor={isCurrent && 'actionSecondary.default'}
      borderWidth={isCurrent ? '2px' : '1px'}
      onClick={() => typeof onClick === 'function' && onClick()}
      {...rest}
    >
      {buttonChild}
    </Button>
  );
};
PaginationButton.propTypes = {
  buttonChild: PropTypes.element,
  isCurrent: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export const PaginationBar = ({ currentPage, pages, onPageChange, ...rest }) => {
  const [state, setState] = useState({
    currentPageIndex: 0,
    pagesArray: [],
    barSlice: [],
  });

  useEffect(() => {
    if (pages > 0) setState({ ...state, currentPageIndex: currentPage - 1, pagesArray: [...Array(pages).keys()] });
  }, [currentPage, pages]);

  const handleButtonClick = pageIndex => {
    setState({ ...state, currentPageIndex: pageIndex });
    onPageChange(pageIndex + 1);
  };

  const handleNextPageClick = () => {
    const nextPageIndex = state.currentPageIndex + 1 < pages ? state.currentPageIndex + 1 : state.currentPageIndex;
    setState({ ...state, currentPageIndex: nextPageIndex });
    onPageChange(nextPageIndex + 1);
  };

  const handlePrevPageClick = () => {
    const nextPageIndex = state.currentPageIndex - 1 >= 0 ? state.currentPageIndex - 1 : state.currentPageIndex;
    setState({ ...state, currentPageIndex: nextPageIndex });
    onPageChange(nextPageIndex + 1);
  };

  const getSliceStart = () => {
    if (state.currentPageIndex + 2 === pages) return state.currentPageIndex - 3 > 0 ? state.currentPageIndex - 3 : 0;
    if (state.currentPageIndex + 1 === pages) return state.currentPageIndex - 4 > 0 ? state.currentPageIndex - 4 : 0;
    if (state.currentPageIndex - 2 >= 0) return state.currentPageIndex - 2;
    return 0;
  };

  const getSliceEnd = () => {
    if (state.currentPageIndex === 0) return state.currentPageIndex + 5;
    if (state.currentPageIndex === 1) return state.currentPageIndex + 4;
    return state.currentPageIndex + 3;
  };
  return (
    <Flex width="100%" justifyContent="center" {...rest}>
      <HStack gap="8px" justifyContent="center" color="gray.800">
        <PaginationButton
          buttonChild={<FaChevronLeft fontSize="20px" />}
          onClick={() => handlePrevPageClick()}
          disabled={state.currentPageIndex - 1 < 0}
        />
        {state.pagesArray.slice(getSliceStart(), getSliceEnd()).map(pageIndex => {
          return (
            <PaginationButton
              key={pageIndex}
              buttonChild={
                <Text as="p" fontSize="12px" fontWeight="normal">
                  {pageIndex + 1}
                </Text>
              }
              onClick={() => handleButtonClick(pageIndex)}
              isCurrent={state.currentPageIndex === pageIndex}
            />
          );
        })}
        <PaginationButton
          buttonChild={<FaChevronRight fontSize="20px" />}
          onClick={() => handleNextPageClick()}
          disabled={state.currentPageIndex + 1 >= pages}
        />
      </HStack>
    </Flex>
  );
};
PaginationBar.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
