import { get, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getEarliestProductionDate, getNextRolloverDate, markLines } from '../helpers';
import './ColdstoreInventoryTable.scss';
import ColdstoreInventoryTableItem from './ColdstoreInventoryTableItem';
import { Box, Table } from '@chakra-ui/react';

const ColdstoreInventoryTableTh = ({ onClick, title }) => {
  return (
    <Table.ColumnHeader
      onClick={() => onClick()}
      color="black"
      fontSize="12px"
      padding="10px 8px"
      borderBottomColor="#B9B9B9"
    >
      {title}{' '}
      <i
        className="sort-icon fa fa-sort"
        style={{
          fontSize: 'inherit',
        }}
      />
    </Table.ColumnHeader>
  );
};

ColdstoreInventoryTableTh.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

class ColdstoreInventoryTable extends Component {
  static propTypes = {
    orders: PropTypes.arrayOf(PropTypes.object).isRequired,
    packerPlants: PropTypes.arrayOf(PropTypes.object).isRequired,
    inputProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
    internalPoLabel: PropTypes.string.isRequired,
    orderDeliveryDates: PropTypes.instanceOf(Object).isRequired,
    onSelectOrder: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
  };

  state = {
    reverseOrder: false,
    sortingColumn: 'grinder_po_number',
  };

  getPackerByUid = id => this.props.packerPlants.find(packerPlant => packerPlant.id === id);

  getCLPointsOfLines = order =>
    order.lines.reduce((agg, line) => {
      return [...agg, line.input_product.cl];
    }, []);

  getSellPricePerUnitofLines = order =>
    order.lines.reduce((agg, line) => {
      return [...agg, line.sell_price_per_unit];
    }, []);

  getOrderDeliveryDate = order => get(this.props.orderDeliveryDates, order.id);

  getSortedOrders = {
    internal_po_number: () => orderBy(this.props.orders, order => order.internal_po_number),
    grinder_po_number: () => orderBy(this.props.orders, order => order.grinder_po_number),
    purchasing_office: () => orderBy(this.props.orders, order => order.purchasing_office),
    delivery_date: () => orderBy(this.props.orders, order => this.getOrderDeliveryDate(order)),
    earliest_used_by_date: () => orderBy(this.props.orders, order => order.earliest_used_by_date),
    earliest_production_date: () => orderBy(this.props.orders, order => getEarliestProductionDate(order)),
    cl_point: () => orderBy(this.props.orders, order => this.getCLPointsOfLines(order)),
    price: () => orderBy(this.props.orders, order => this.getSellPricePerUnitofLines(order)),
    carton_count: () => orderBy(this.props.orders, order => get(order, `lines[0].carton_count`)),
    storage_date: () => orderBy(this.props.orders, order => getNextRolloverDate(order)),
    coldstore: () => orderBy(this.props.orders, order => order.coldstore_details?.cold_store?.location_name),
    coldstore_lot: () => orderBy(this.props.orders, order => order.coldstore_details?.lot_numbers.join(', ')),
    packer_plant: () => orderBy(this.props.orders, order => this.getPackerByUid(order.packer_plant_id)?.name),
    est: () => orderBy(this.props.orders, order => this.getPackerByUid(order.packer_plant_id).est),
  };

  sortBy = field => {
    if (this.state.sortingColumn === field) {
      const { reverseOrder } = this.state;
      this.setState({ reverseOrder: !reverseOrder });
    }
    this.setState({ sortingColumn: field });
  };

  render() {
    return (
      <>
        <Box whiteSpace="auto" overflowX="auto">
          <Table.Root size="sm">
            <Table.Header backgroundColor="card.default">
              <ColdstoreInventoryTableTh
                onClick={() => this.sortBy('internal_po_number')}
                title={this.props.internalPoLabel}
              />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('grinder_po_number')} title="Grinder PO#" />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('purchasing_office')} title="Purchasing Office" />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('delivery_date')} title="Delivery Date" />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('earliest_used_by_date')} title="Used by Date" />
              <ColdstoreInventoryTableTh
                onClick={() => this.sortBy('earliest_production_date')}
                title="Earliest Production Date"
              />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('cl_point')} title="CL" />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('price')} title="Grinder Delivered Price" />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('storage_date')} title="Storage Date" />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('coldstore')} title="Coldstore" />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('packer_plant')} title="Establishment" />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('est')} title="EST" />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('carton_count')} title="Case Count" />
              <ColdstoreInventoryTableTh onClick={() => this.sortBy('coldstore_lot')} title="Lot Number" />
            </Table.Header>
            <Table.Body backgroundColor="white">{this.renderOrders()}</Table.Body>
          </Table.Root>
        </Box>
      </>
    );
  }

  renderOrders = () => {
    const { inputProducts, packerPlants, onSelectOrder, onDateChange, orderDeliveryDates } = this.props;

    let orders = this.getSortedOrders[this.state.sortingColumn]();
    if (this.state.reverseOrder) {
      orders.reverse();
    }
    orders = markLines([...orders]);

    /*
     * Reduce orders to an order lines array.
     * Add reference to parent order for each line and selection status
     */
    const allLines = orders.reduce((agg, order) => {
      const augmentedLines = order.lines.map(line => ({
        ...line,
        order,
      }));

      return [...agg, ...augmentedLines];
    }, []);

    const nodes = allLines.map(line => (
      <ColdstoreInventoryTableItem
        key={line.id}
        selected={line.order.selected}
        order={line.order}
        line={line}
        inputProducts={inputProducts}
        packerPlants={packerPlants}
        orderDeliveryDates={orderDeliveryDates}
        onSelectOrder={onSelectOrder}
        onDateChange={onDateChange}
      />
    ));

    return nodes;
  };
}

export default ColdstoreInventoryTable;
