import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Heading, HStack, VStack } from '@chakra-ui/react';
import RightAngleIcon from './RightAngleIcon';
import { isEqual } from 'lodash';

const DashboardSectionTile = ({ children, title, tileStatusComponent, useDefaultWidth, headerFlexStyle, ...rest }) => {
  const TileStatusComponent = tileStatusComponent;

  return (
    <VStack
      align="stretch"
      gap="20px"
      padding="21px 51px 32px 43px"
      boxShadow="md"
      borderRadius="15px"
      backgroundColor="card.default"
      {...(useDefaultWidth && {
        width: {
          sm: '320px',
          md: '768px',
          lg: '860px',
          xl: '1266px',
          '2xl': '1746px',
        },
      })}
      {...rest}
    >
      {title && (
        <Flex alignItems="center" width="100%" {...headerFlexStyle}>
          <HStack gap="10px">
            <RightAngleIcon />
            <Heading className="dashboard-section__heading" fontSize="15px">
              {title}
            </Heading>
          </HStack>
          {tileStatusComponent && <TileStatusComponent />}
        </Flex>
      )}
      {children}
    </VStack>
  );
};

DashboardSectionTile.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  useDefaultWidth: PropTypes.string,
  tileStatusComponent: PropTypes.elementType,
  padding: PropTypes.string,
  spacing: PropTypes.string,
  headerFlexStyle: PropTypes.shape({}),
};

const areEqual = (prevProps, nextProps) => {
  const keys = ['changeableKey', 'title', 'useDefaultWidth', 'tileStatusComponent', 'children', 'padding', 'spacing'];
  for (const key of keys) {
    if (!isEqual(prevProps[key], nextProps[key])) {
      return false;
    }
  }
  return true;
};

export default React.memo(DashboardSectionTile, areEqual);
