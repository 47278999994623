import React, { Component } from 'react';
import moment from 'moment';
import { startCase, isEmpty } from 'lodash';
import RecipientsCard from './RecipientCard/RecipientsCard';
import { checkForInvalidOrders } from '../helpers';
import './EmailModal.scss';
import { getEmailTemplate } from '../../../services/email-templates';
import { Text, Button as ChakraButton, Box, Input as ChakraInput, VStack, Textarea, Center } from '@chakra-ui/react';
import { EmailModalBaseComponent, EmailModalOrderDetails } from './EmailModalBase';
import { Alert } from '@/components/ui/alert';
import { Field } from '@/components/ui/field';

class EmailModal extends Component {
  state = {
    isLoading: false,
    hasEmailTriggered: false,
  };

  async UNSAFE_componentWillMount() {
    this.setState({
      subject: await this.getDefaultSubject(),
      message: await this.getDefaultMessage(),
    });
  }

  onExit = () => {
    this.props.closeModal();
  };

  getDefaultSubject = async () => {
    const { grinder, transporter, orders, coldstore } = this.props.data;
    const minDeliveryDate = orders
      .map(o => o.delivery_date)
      .reduce((d1, d2) => {
        return d1 < d2 ? d1 : d2;
      });
    const loadNumbers = orders.map(o => o.internal_po_number).join(',');
    const minDeliveryDateFormatted = moment(minDeliveryDate, 'YYYY-MM-DD').startOf('isoWeek').format('MM/DD/YYYY');
    const emailTemplate = await getEmailTemplate('coldstore_grinder_release_subject', {
      transporter_name: transporter.name,
      location_name: coldstore.location_name,
      grinder_name: grinder.name,
      load_numbers: loadNumbers,
      minDeliveryDateFormatted,
    });

    return emailTemplate;
  };

  getDefaultMessage = async () => {
    const { grinder, transporter } = this.props.data;
    const loggedInUser = `${startCase(this.props.user.first_name)} ${startCase(this.props.user.last_name)}`;
    const loggedWithEmail = this.props.user.email;
    const emailTemplate = await getEmailTemplate('coldstore_grinder_release_body', {
      transporter_name: transporter.name,
      grinder_name: grinder.name,
      loggedInUser,
      loggedWithEmail,
    });

    return emailTemplate;
  };

  getSelectedEmailList = selectedEmailList => {
    this.setState({ selectedEmailList });
  };

  renderModalBodyContent() {
    const { data, coldstoreSendEmailError, user } = this.props;
    const { hasEmailTriggered, subject, message } = this.state;
    const { grinder, transporter, coldstore, orders } = data;
    const { packer_plant_id } = orders[0];
    const invalidOrders = checkForInvalidOrders(orders);

    return (
      <VStack align="stretch" gap="14px">
        {coldstoreSendEmailError ? (
          <Alert
            status="error"
            title="Could not send email. Please contact administrator with time of error and PO details."
          />
        ) : (
          hasEmailTriggered && (
            <Alert status="info" title="Email(s) in progress, pop-up will close after email is sucessfully sent." />
          )
        )}

        <EmailModalOrderDetails
          grinderName={grinder.name}
          coldstoreLocationName={coldstore.location_name}
          transporterName={transporter.name}
        />

        <VStack align="stretch" gap="37px" paddingX="50px">
          <Box>
            <Field label="Subject" fontSize="16px" marginLeft="16px">
              <ChakraInput
                width="571px"
                height="48px"
                borderRadius="8px"
                fontSize="16px"
                borderColor="neutral.6"
                value={subject}
                onChange={e => this.setState({ subject: e.target.value })}
              />
            </Field>
          </Box>
          <Box>
            <Field label="Message" fontSize="16px" marginLeft="16px">
              <Textarea
                width="598px"
                borderRadius="8px"
                borderColor="neutral.6"
                rows={14}
                value={message}
                onChange={e => this.setState({ message: e.target.value })}
              />
            </Field>
          </Box>
          {/* <!-- RECIPIENTS CARD --> */}
          <RecipientsCard
            disabled={this.props.coldstoreSendEmailError}
            grinderUid={grinder.uid}
            transporterId={transporter.id}
            coldstoreId={coldstore.id}
            packerPlantId={packer_plant_id}
            notifying_action_groups={this.props.notifying_action_groups}
            populateEmails={this.getSelectedEmailList}
            actionGroups={['coldstore_released', 'internal_users']}
            defaultActionGroups={['coldstore_released', 'internal_users']}
            extraEmails={[user.email]}
          />
          {/* <!-- INVALID ORDERS MESSAGE --> */}
          {!isEmpty(invalidOrders) && (
            <Alert status="warning">
              <div>
                The following order(s) have a pickup date that has already been locked:
                <p>{invalidOrders.map(order => order.id).join(', ')}</p>
                Changing the pickup date for these order(s) will result in charges for an additional roll window not
                being recovered.
              </div>
            </Alert>
          )}
        </VStack>
      </VStack>
    );
  }

  renderModalFooterContent() {
    const { coldstoreSendEmailError } = this.props;
    const { isLoading } = this.state;
    return (
      <>
        {isLoading ? (
          <Center width="100%">
            <Text as="p" fontWeight="bold" fontSize="16px" color="primary.default">
              Loading...
            </Text>
          </Center>
        ) : (
          <ChakraButton
            variant="solid"
            colorScheme="actionPrimary"
            key="submit"
            type="primary"
            height="40px"
            width="168px"
            fontSize="16px"
            fontWeight="black"
            disabled={coldstoreSendEmailError}
            onClick={this.onSend}
          >
            Send
          </ChakraButton>
        )}
      </>
    );
  }

  render() {
    const { visible } = this.props;
    return (
      visible && (
        <EmailModalBaseComponent
          visible={visible}
          onExit={this.onExit}
          headerTitle="Email"
          modalBodyContent={this.renderModalBodyContent()}
          modalFooterContent={this.renderModalFooterContent()}
        />
      )
    );
  }

  onSend = async () => {
    this.setState({ isLoading: true, hasEmailTriggered: true });
    await this.props.onSendEmails(
      this.props.data.orders,
      this.state.subject,
      this.state.message,
      this.state.selectedEmailList
    );
    this.setState({ isLoading: false, hasEmailTriggered: false });
  };
}

export default EmailModal;
