import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { Tooltip } from '@/components/ui/tooltip';

const ResolutionButton = ({ isResolved, onClick }) => {
  const [resolved, setResolved] = useState(false);
  useEffect(() => {
    setResolved(isResolved);
  }, [isResolved]);

  const handleClick = () => {
    onClick();
    setResolved(!resolved);
  };

  return (
    <Tooltip hasArrow content={resolved ? 'This task is resolved' : 'Click to resolve task'} bg="black">
      <IconButton
        colorScheme={resolved ? 'accent.three' : 'actionNeutral'}
        onClick={() => handleClick()}
        width="25px"
        minWidth="25px"
        height="25px"
        borderRadius="25px"
        boxShadow={resolved ? '0px 4px 4px var(--chakra-colors-blackAlpha-400) inset' : 'none'}
      >
        <FaCheck width="12px" color="white" />
      </IconButton>
    </Tooltip>
  );
};

ResolutionButton.propTypes = {
  isResolved: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ResolutionButton;
