import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  getColdStoreReleasePDF,
  resetColdStoreEmailModal,
  saveOrder,
  sendEmailWithReleasePDF,
} from '../../../actions/actions_coldstore_orders_util';
import Loading from '../../basic/Loading';
import { checkForInvalidOrders } from '../helpers';
import './EditReleaseModal.scss';
import RecipientsCard from './RecipientCard/RecipientsCard';
import { downloadFromS3Link } from '../../../helpers/downloads';
import { getEmailTemplate } from '../../../services/email-templates';
import { Box, Flex, Text, Button as ChakraButton, HStack, VStack, Input, Textarea, IconButton } from '@chakra-ui/react';
import { EmailModalBaseComponent, EmailModalOrderDetails } from './EmailModalBase';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import StyledAlert from './StyledAlert';
import { Alert } from '../../ui/alert';
import { Field } from '@/components/ui/field';

class EditReleaseModal extends Component {
  state = {
    ordersMap: this.props.ordersMap,
    previewOrder: {
      url: null,
      name: null,
    },
    isOrderChanged: false,
    isPreviewLoading: false,
    isEmailTemplateEnabled: false,
    isGrinderTableEditable: true,
    isReleaseCancelled: false,
    hasEmailTriggered: false,
    updatedFields: [],
    updatedTransporterName: '',
    emailSubject: '',
    emailBody: '',
    emails: [],
    showNoEmailIdErrorMsg: false,
    showAlert: true,
  };

  static updatedPdfTitle = { title: 'Delivery Change', fontColour: 'red' };

  static cancelledPdfTitle = { title: 'Delivery Cancellation', fontColour: 'red' };

  static formatDate(dateString) {
    const mmnt = moment(dateString, 'YYYY-MM-DD');
    if (!mmnt || !moment.isMoment(mmnt)) {
      return dateString;
    }
    return mmnt.format('MM/DD/YYYY');
  }

  async componentDidMount() {
    const { actionType } = this.props;
    if (actionType === 'edit') {
      await this.makeChanges();
    } else if (actionType === 'cancel') {
      await this.cancelRelease();
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedOrder, previewOrders, isColdstoreEmailSent, clearOrder, isEditReleaseModalOpen, dispatch } =
      this.props;
    const { isEmailTemplateEnabled, hasEmailTriggered, isReleaseCancelled } = this.state;
    // when changes are made, wait till the order has updated
    if (selectedOrder[0] && selectedOrder[0] !== prevProps.selectedOrder[0]) {
      this.fetchPreviewPDF(EditReleaseModal.updatedPdfTitle);
    }
    // read pdf link when available
    if (previewOrders && isEmailTemplateEnabled && prevProps.previewOrders !== previewOrders) {
      this.setState({
        previewOrder: { url: previewOrders.url, name: previewOrders.name },
        isPreviewLoading: false,
      });
    }
    // close modal when email is successfully sent and reload dialog
    if (isColdstoreEmailSent && hasEmailTriggered) {
      // This will clear the order transport details. Do it after email sent.
      if (isReleaseCancelled) {
        clearOrder(selectedOrder[0]);
      }
      isEditReleaseModalOpen(false);
      dispatch(resetColdStoreEmailModal());
    }
  }

  emailColdstoreEditedRelease = (order, subject, body, selectedEmailList) => {
    if (!selectedEmailList.length) this.setState({ showNoEmailIdErrorMsg: true });
    else this.setState({ showNoEmailIdErrorMsg: false });

    const payload = {
      purchase_order_ids: [order.id],
      emails: selectedEmailList,
      email_subject: subject,
      email_body: body,
    };
    if (this.state.isReleaseCancelled) {
      payload.pdfTitle = EditReleaseModal.cancelledPdfTitle;
    } else {
      payload.pdfTitle = EditReleaseModal.updatedPdfTitle;
    }
    this.props.dispatch(sendEmailWithReleasePDF(payload, [order], this.props.token));
    this.setState({ hasEmailTriggered: true });
  };

  handleValueChange = (id, key, value) => {
    let valueToChange = value === undefined || value === '' ? null : value;

    if (key && key.includes('_date') && !moment(value).isValid()) {
      valueToChange = null;
    }
    const { ordersMap } = this.state;
    const newOrdersMap = { ...ordersMap };
    newOrdersMap[id][key] = valueToChange;
    this.setState({ ordersMap: newOrdersMap });

    if (key === 'null_delivery_time') {
      if (valueToChange === true) {
        this.handleValueChange(id, 'scheduled_delivery_time', null);
      }
    }
  };

  onExit = () => {
    this.props.isEditReleaseModalOpen(false);
    this.props.clearColdStoreEmailModal();
  };

  cancelRelease = async () => {
    // fetch preview PDF and update necessary state variables
    this.fetchPreviewPDF(EditReleaseModal.cancelledPdfTitle);

    const selectedOrder = cloneDeep(this.props.selectedOrder[0]);
    this.setState({
      isReleaseCancelled: true,
      emailSubject: await getEmailTemplate('coldstore_cancel_release_subject', {
        grinder_name: selectedOrder.grinder.name,
        cancellation_date: moment().format('MM-DD-YYYY'),
        coldstore: selectedOrder.coldstore_details.cold_store.location_name,
        transporter: selectedOrder.transporter.name,
      }),
      emailBody: await getEmailTemplate('coldstore_cancel_release_body', {
        grinder_name: selectedOrder.grinder.name,
        delivery_date: EditReleaseModal.formatDate(selectedOrder.delivery_date),
        shipment_date: EditReleaseModal.formatDate(selectedOrder.shipment_date),
        grinder_po_number: selectedOrder.grinder_po_number,
        lot_numbers: selectedOrder.coldstore_details.lot_numbers,
      }),
    });
  };

  fetchPreviewPDF = pdfTitle => {
    const payload = {
      purchase_order_ids: [this.props.selectedOrder[0].id],
      pdfTitle,
    };
    this.props.dispatch(getColdStoreReleasePDF(payload, this.props.token));
    this.setState({ isPreviewLoading: true, isEmailTemplateEnabled: true });
  };

  makeChanges = async () => {
    const { ordersMap } = this.state;
    const updatedOrder = cloneDeep(ordersMap[this.props.selectedOrder[0].id]);
    const payload = {
      delivery_date: updatedOrder.delivery_date,
      shipment_date: updatedOrder.shipment_date,
      scheduled_delivery_time: updatedOrder.scheduled_delivery_time,
      transporter_id: updatedOrder.transporter_id,
    };
    // save changes
    this.props.dispatch(saveOrder(payload, updatedOrder.id, this.props.token));

    // email content
    const unEditedOrder = cloneDeep(this.props.unEditedOrder[0]);
    // eslint-disable-next-line prettier/prettier
    const updatedTransporterName = this.props.transporters.find(t => t.id === updatedOrder.transporter_id)?.name;
    const updatedFields = [];
    let coldStoreUpdatedInfo = '';
    if (unEditedOrder.delivery_date !== updatedOrder.delivery_date) {
      updatedFields.push('Delivery Date');
      coldStoreUpdatedInfo += `Delivery Date: ${EditReleaseModal.formatDate(
        unEditedOrder.delivery_date
      )} -> ${EditReleaseModal.formatDate(updatedOrder.delivery_date)} \n`;
    }
    if (unEditedOrder.scheduled_delivery_time !== updatedOrder.scheduled_delivery_time) {
      updatedFields.push('Delivery Time');
      coldStoreUpdatedInfo += `Delivery Time: ${unEditedOrder.scheduled_delivery_time} -> ${updatedOrder.scheduled_delivery_time} \n`;
    }

    if (unEditedOrder.shipment_date !== updatedOrder.shipment_date) {
      updatedFields.push('Pickup Date');
      coldStoreUpdatedInfo += `Pickup Date: ${EditReleaseModal.formatDate(
        unEditedOrder.shipment_date
      )} -> ${EditReleaseModal.formatDate(updatedOrder.shipment_date)} \n`;
    }

    if (unEditedOrder.transporter_id !== updatedOrder.transporter_id) {
      updatedFields.push('Trucking Company');
      coldStoreUpdatedInfo += `Trucking Company: ${unEditedOrder.transporter.name} -> ${updatedTransporterName} \n`;
    }

    this.setState({
      emailSubject: await getEmailTemplate('coldstore_edit_release_subject', {
        delivery_date: EditReleaseModal.formatDate(updatedOrder.delivery_date),
        grinder_name: unEditedOrder.grinder.name,
        updatedTransporterName,
        location_name: unEditedOrder.coldstore_details.cold_store.location_name,
      }),
      emailBody: await getEmailTemplate('coldstore_edit_release_body', {
        updatedFields,
        grinder_name: unEditedOrder.grinder.name,
        coldStoreUpdatedInfo,
        grinder_po_number: unEditedOrder.grinder_po_number,
        location_name: unEditedOrder.coldstore_details.cold_store.location_name,
        lot_numbers: unEditedOrder.coldstore_details.lot_numbers
          ? unEditedOrder.coldstore_details.lot_numbers.join(', ')
          : '',
      }),
      // fetch preview PDF and update necessary state variables
      isEmailTemplateEnabled: true,
    });
  };

  getSelectedEmailList = selectedEmailList => {
    this.setState({ selectedEmailList });
  };

  renderModalBodyContent() {
    const { coldstoreSendEmailError, user, selectedOrder, notifying_action_groups: notifyingActionGroups } = this.props;
    const {
      hasEmailTriggered,
      showAlert,
      emailSubject,
      emailBody,
      isPreviewLoading,
      previewOrder,
      showNoEmailIdErrorMsg,
      isEmailTemplateEnabled,
    } = this.state;
    const invalidOrders = checkForInvalidOrders(selectedOrder);

    return (
      <VStack align="stretch" gap="14px">
        <Flex justifyContent="center" paddingBottom="14px" borderBottomWidth="1px">
          {hasEmailTriggered && showAlert ? (
            <Box>
              {coldstoreSendEmailError ? (
                <StyledAlert
                  status="error"
                  infoMessage="Could not send email. Please contact administrator with time of error and PO details."
                  onClick={() => this.setState({ showAlert: false })}
                />
              ) : (
                <StyledAlert
                  status="info"
                  infoMessage="Email triggered, pop-up will auto close when email is successfully sent"
                  onClick={() => this.setState({ showAlert: false })}
                />
              )}
            </Box>
          ) : (
            <Text as="p" fontWeight="bold">
              You are now editing this order. Click the “Email” button to commit & communicate any values changed below
              for this order.
            </Text>
          )}
        </Flex>
        {selectedOrder.map(order => (
          <EmailModalOrderDetails
            grinderName={order.grinder.name}
            coldstoreLocationName={order.coldstore_details?.cold_store?.location_name}
            transporterName={order.transporter?.name}
            borderBottomWidth="0px"
            marginY="24px !important"
            paddingBottom="0px"
          />
        ))}
        <VStack align="stretch" gap="37px" paddingLeft="50px">
          <Box>
            <Field label="Subject" fontSize="16px" marginLeft="16px">
              <Input
                width="571px"
                height="48px"
                borderRadius="8px"
                fontSize="16px"
                borderColor="neutral.6"
                value={emailSubject}
                onChange={e => this.setState({ emailSubject: e.target.value })}
              />
            </Field>
          </Box>
          <HStack gap="35px" alignItems="flex-start">
            <Box>
              <Field label="Message" fontSize="16px" marginLeft="16px">
                <Textarea
                  width="598px"
                  borderRadius="8px"
                  borderColor="neutral.6"
                  rows={14}
                  value={emailBody}
                  onChange={e => this.setState({ emailBody: e.target.value })}
                />
              </Field>
            </Box>
            <Box>
              <label fontSize="16px">Attachments</label>
              <Box>
                {/* hide attachment box while fetching in progress */}
                {isPreviewLoading ? (
                  <Loading style={{ padding: '0px' }} />
                ) : (
                  <HStack gap="17px">
                    <Box
                      textOverflow="ellipsis"
                      height="48px"
                      width="121px"
                      borderRadius="8px"
                      backgroundColor="neutral.7"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      padding="12px 16px"
                      fontSize="16px"
                    >
                      {previewOrder.name}
                    </Box>
                    <IconButton
                      variant="link"
                      href="#"
                      fontSize="18px"
                      onClick={e => {
                        e.preventDefault();
                        downloadFromS3Link(previewOrder.url);
                      }}
                    >
                      <MdOutlineKeyboardArrowDown />
                    </IconButton>
                  </HStack>
                )}
              </Box>
            </Box>
          </HStack>
          {showNoEmailIdErrorMsg && (
            <Alert status="error" title="No email ids specified / attached to the groups selected." />
          )}
          {!isEmpty(invalidOrders) && (
            <Alert status="error">
              <Box>
                The following order(s) have a pickup date that has already been locked:
                <p>{invalidOrders.map(order => order.id).join(', ')}</p>
                Changing the pickup date for these order(s) will result in charges for an additional roll window not
                being recovered.
              </Box>
            </Alert>
          )}
          {selectedOrder[0] && (
            <RecipientsCard
              disabled={!isEmailTemplateEnabled}
              grinderUid={selectedOrder[0].grinder_uid}
              transporterId={selectedOrder[0].transporter_id}
              coldstoreId={selectedOrder[0].coldstore_details.cold_store_id}
              packerPlantId={selectedOrder[0].packer_plant_id}
              notifying_action_groups={notifyingActionGroups}
              populateEmails={this.getSelectedEmailList}
              actionGroups={['coldstore_released', 'internal_users']}
              defaultActionGroups={['coldstore_released', 'internal_users']}
              extraEmails={[user.email]}
            />
          )}
        </VStack>
      </VStack>
    );
  }

  renderModalFooterContent() {
    const { selectedOrder } = this.props;
    const { isPreviewLoading, hasEmailTriggered, emailSubject, emailBody, selectedEmailList } = this.state;
    return (
      <ChakraButton
        variant="solid"
        colorScheme="actionPrimary"
        key="email"
        type="primary"
        height="40px"
        width="168px"
        fontSize="16px"
        fontWeight="black"
        disabled={isPreviewLoading || hasEmailTriggered}
        onClick={() => {
          this.emailColdstoreEditedRelease(selectedOrder[0], emailSubject, emailBody, selectedEmailList);
        }}
      >
        Send
      </ChakraButton>
    );
  }

  render() {
    const { showEditReleaseModal } = this.props;
    return (
      <EmailModalBaseComponent
        visible={showEditReleaseModal}
        onExit={this.onExit}
        headerTitle="Edit Release"
        modalBodyContent={this.renderModalBodyContent()}
        modalFooterContent={this.renderModalFooterContent()}
      />
    );
  }
}

EditReleaseModal.propTypes = {
  ordersMap: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      delivery_date: PropTypes.string,
      shipment_date: PropTypes.string,
      scheduled_delivery_time: PropTypes.string,
      transporter_id: PropTypes.number,
      grinder: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      coldstore_details: PropTypes.shape({
        cold_store: PropTypes.shape({
          location_name: PropTypes.string.isRequired,
        }),
        lot_numbers: PropTypes.arrayOf(PropTypes.string),
      }),
      transporter: PropTypes.shape({
        name: PropTypes.string,
      }),
      grinder_po_number: PropTypes.string,
    })
  ),
  actionType: PropTypes.string,
  selectedOrder: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      delivery_date: PropTypes.string,
      shipment_date: PropTypes.string,
      scheduled_delivery_time: PropTypes.string,
      transporter_id: PropTypes.number,
      grinder: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      coldstore_details: PropTypes.shape({
        cold_store: PropTypes.shape({
          location_name: PropTypes.string.isRequired,
        }),
        lot_numbers: PropTypes.arrayOf(PropTypes.string),
      }),
      transporter: PropTypes.shape({
        name: PropTypes.string,
      }),
      grinder_po_number: PropTypes.string,
    })
  ),
  previewOrders: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  }),
  isColdstoreEmailSent: PropTypes.bool,
  clearOrder: PropTypes.func,
  isEditReleaseModalOpen: PropTypes.func,
  dispatch: PropTypes.func,
  token: PropTypes.string,
  clearColdStoreEmailModal: PropTypes.func,
  unEditedOrder: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      delivery_date: PropTypes.string,
      shipment_date: PropTypes.string,
      scheduled_delivery_time: PropTypes.string,
      transporter_id: PropTypes.number,
      grinder: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      coldstore_details: PropTypes.shape({
        cold_store: PropTypes.shape({
          location_name: PropTypes.string.isRequired,
        }),
        lot_numbers: PropTypes.arrayOf(PropTypes.string),
      }),
      transporter: PropTypes.shape({
        name: PropTypes.string,
      }),
      grinder_po_number: PropTypes.string,
    })
  ),
  transporters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  coldstoreSendEmailError: PropTypes.string,
  notifying_action_groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  showEditReleaseModal: PropTypes.bool,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
};

export default EditReleaseModal;
