import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Center, Button, IconButton } from '@chakra-ui/react';
import { Link, useParams } from 'react-router';
import moment from 'moment';
import { downloadFromS3Link } from '../../../helpers/downloads';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import ArticleTile from './ArticleTile';

const KPICard = ({ article = {} }) => {
  const { region } = useParams();
  const onClick = e => {
    e.stopPropagation();
    downloadFromS3Link(article.attachment_url);
  };
  return (
    <ArticleTile imageUrl={article.hero_url}>
      <Box>
        <Box height="34px">
          <Heading
            height="100%"
            fontSize="16px"
            fontWeight="600"
            lineHeight="28px"
            textAlign="center"
            overflow="hidden"
          >
            {article.title}
          </Heading>
        </Box>
        <Center marginTop="14px">
          <Heading fontSize="14px" fontWeight="600">
            {moment(article.published_date).format('MMMM YYYY')}
          </Heading>
        </Center>
      </Box>
      <Center marginTop="20px">
        <Link to={`/kpi/${region}/${article.slug}`}>
          <Button
            w="138px"
            height="48px"
            colorScheme="actionPrimary"
            fontSize="16px"
            fontWeight="600"
            borderRadius="10px"
            mr="2"
          >
            View
          </Button>
        </Link>
        {article.attachment_url && (
          <IconButton
            variant="outline"
            width="48px"
            height="48px"
            onClick={onClick}
            iconColor="grey"
            type="ghost"
            colorScheme="actionNeutral"
          >
            <FaCloudDownloadAlt />
          </IconButton>
        )}
      </Center>
    </ArticleTile>
  );
};

KPICard.propTypes = {
  article: PropTypes.instanceOf(Object),
};

export default KPICard;
