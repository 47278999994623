import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '@chakra-ui/react';
import { ErrorMessage } from 'formik';
import { Field } from '@/components/ui/field';

import DateField from './fields/DateField';

const propTypes = {
  closeOnSelect: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  inputProps: PropTypes.shape({
    borderColor: PropTypes.string,
  }),
  name: PropTypes.string,
  timeFormat: PropTypes.string,
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  indices: PropTypes.arrayOf(PropTypes.number),
};

function FormDatePicker(props) {
  const { name, inputProps, closeOnSelect, value, onChange, timeFormat, dateFormat, label, indices, ...rest } = props;
  const fieldName = typeof name === 'function' ? name(indices) : name;

  return (
    <Box>
      <Field label={label} name={fieldName}>
        <DateField
          {...{
            id: name,
            name: fieldName,
            inputProps,
            closeOnSelect,
            value,
            onChange,
            timeFormat,
            dateFormat,
            ...rest,
          }}
        />
      </Field>
      <ErrorMessage name={fieldName}>{msg => <Text color="error">{msg}</Text>}</ErrorMessage>
    </Box>
  );
}

FormDatePicker.propTypes = propTypes;

export default FormDatePicker;
