import React, { useEffect, useState } from 'react';
import { Box, Table, Text, Flex } from '@chakra-ui/react';
import SelectField from '../basic/SelectField';
import { PaginationBar } from '../core/PaginationComponents';
import { get } from 'lodash';
import BuyActivityPaneBody from './BuyActivityPaneBody';
import Card, { CardTitle, CardBodyGroup } from '../basic/Card';
import Loading from '../basic/Loading';
import WatermarkText from './WatermarkText';
import { commify, formatMonetaryValue } from '../../functions';
import './BuyActivityStream.scss';
import Constants from '../../Constants';
import moment from 'moment';
import { fetchOrderStatusMap, selectOrderStatusMap } from '../../slices/order-status-map/orderStatusMapSlice';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const selectFieldProps = {
  isClearable: true,
  controlStyles: {
    background: 'none',
    border: 0,
    boxShadow: 'none',
  },
  placeholderStyles: {
    color: '#979797',
  },
};

function BuyActivityStream(props) {
  const [state, setState] = useState({
    buyer: undefined,
    cl: undefined,
    batchSize: 50,
    clearOld: true,
    productType: Constants.PRODUCT_TYPE_LIST.BEEF,
    fresh_or_frozen: undefined,
    grinderId: undefined,
    packerPlantId: undefined,
    productId: null,
    queryType: 'cl',
    currentPage: 1,
  });

  const {
    token,
    dispatch,
    configs,
    recentPOs,
    recentPOFilters,
    requesting,
    error,
    users,
    cls,
    inputProducts,
    grinders,
    packerPlants,
  } = props;

  const {
    buyer,
    cl,
    batchSize,
    clearOld,
    productType,
    fresh_or_frozen,
    grinderId,
    packerPlantId,
    queryType,
    productId,
    currentPage,
  } = state;

  const orderStatus = useSelector(selectOrderStatusMap);
  const getData = () => {
    const { getRecentPOActivity } = props;
    getRecentPOActivity({
      buyer,
      cl,
      clearOld,
      limit: batchSize,
      offset: (currentPage - 1) * batchSize,
      productType,
      fresh_or_frozen,
      grinderId,
      packerPlantId,
      queryType,
      productId,
      page: currentPage,
    });
  };

  const getFilters = () => {
    const { getRecentPOActivity } = props;
    const propName = queryType === 'cl' ? 'cl' : 'productName';
    getRecentPOActivity({
      queryType,
      showOnly: ['orderedByID', 'productType', propName],
    });
  };

  const getMoreData = page => {
    setState(prev => ({
      ...prev,
      currentPage: page,
      clearOld: false,
    }));
    getData();
  };

  const fetchData = async () => {
    getData();
    getFilters();
  };

  useEffect(() => {
    dispatch(fetchOrderStatusMap());
    fetchData();
  }, [dispatch, token]);

  useEffect(() => {
    fetchData();
  }, [queryType]);

  const renderBuyActivityTable = () => {
    const colName = queryType === 'cl' ? 'Lean Point' : 'Product';
    const propName = queryType === 'cl' ? 'cl' : 'productName';
    const internalPoLabel = configs?.find(o => o.name === 'internal_po_number')?.value_str;

    if (recentPOs.pos.length === 0) {
      return <WatermarkText>No POs matching that criteria</WatermarkText>;
    }

    const TableHeading = ({ children, ...rest }) => (
      <Table.ColumnHeader color="black" paddingX={2} fontSize="md" {...rest}>
        {children}
      </Table.ColumnHeader>
    );

    const TableData = ({ children, ...rest }) => (
      <Table.Cell paddingX={4} fontSize="md" {...rest}>
        {children}
      </Table.Cell>
    );

    return (
      <Box bgColor="white">
        <Table.Root size="md">
          <Table.Header backgroundColor="gray.50">
            <Table.Row>
              <TableHeading>Buyer</TableHeading>
              {/* <TableHeading>Office</TableHeading> */}
              <TableHeading>Protein</TableHeading>
              <TableHeading>{colName}</TableHeading>
              <TableHeading textAlign="right">CIF Buy Price</TableHeading>
              <TableHeading textAlign="right">Volume</TableHeading>
              <TableHeading>Establishment</TableHeading>
              <TableHeading>Grinder</TableHeading>
              <TableHeading>{internalPoLabel}</TableHeading>
              <TableHeading>Order Created</TableHeading>
              <TableHeading>Shipment Date</TableHeading>
              <TableHeading>Status</TableHeading>
              <TableHeading textAlign="right">Total Price</TableHeading>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {recentPOs.pos.map((po, index) => {
              return (
                <Table.Row key={`${index}-${po.orderedOn}-${po.grinderName}`}>
                  <TableData>{po.orderedBy}</TableData>
                  {/* <TableData>{po.office}</TableData> */}
                  <TableData>{po.productType}</TableData>
                  <TableData>{po[propName]}</TableData>
                  <TableData textAlign="right">
                    {formatMonetaryValue(po.buyCurrency, po.buyPricePerUnit, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    })}
                  </TableData>
                  <TableData textAlign="right">
                    {commify(po.volume)} {po.unitOfMeasure}
                  </TableData>
                  <TableData>{po.packerPlantName}</TableData>
                  <TableData>{po.grinderName}</TableData>
                  <TableData>{po.internalPoNumber}</TableData>
                  <TableData>{moment(po.orderedOn).format('DD/MM/YYYY')}</TableData>
                  <TableData>{po.shipmentDate ? po.shipmentDate : 'None'}</TableData>
                  {/* <TableData>{po.status}</TableData> */}
                  <TableData>{orderStatus?.[po.status] || 'Loading…'}</TableData>
                  <TableData textAlign="right">
                    {formatMonetaryValue(po.buyCurrency, po.total, {
                      notation: 'standard',
                    })}
                  </TableData>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
        <PaginationBar
          currentPage={state.currentPage}
          pages={Math.ceil(parseInt(recentPOs?.total_count, 10) / 50) || 100}
          onPageChange={page => {
            getMoreData(page);
          }}
          justifyContent="flex-end"
          marginY={6}
          paddingX={5}
        />
      </Box>
    );
  };

  const buyers = get(recentPOFilters.filters, 'orderedByID', []);
  const sortedUsers = [...users]
    .filter(user => buyers.includes(user.id))
    .sort((a, b) => (`${a.first_name} ${a.last_name}` < `${b.first_name} ${b.last_name}` ? -1 : 1));
  const buyerOptions = sortedUsers.map(user => ({
    value: user.id,
    label: `${user.first_name} ${user.last_name}`,
  }));

  if (buyers.includes(null)) {
    buyerOptions.push({
      value: 'unknown',
      label: 'Unknown',
    });
  }

  const proteins = get(recentPOFilters.filters, 'productType', []);
  const proteinOptions = Constants.PRODUCT_TYPES.filter(pType => proteins.includes(pType.value));

  const grinderOptions = [
    ...new Set(
      grinders.map(grinder => ({
        value: grinder.uid,
        label: grinder.name,
      }))
    ),
  ];

  const EstablimentOptions = [
    ...new Set(
      packerPlants.map(packerPlant => ({
        value: packerPlant.id,
        label: packerPlant.name,
      }))
    ),
  ];

  let targetCol = <></>;
  if (queryType === 'cl') {
    const clOptions = [...new Set(cls.filter(clValue => propNames?.includes(Number(clValue))))].map(clOption => ({
      value: clOption,
      label: clOption,
    }));
    targetCol = (
      <SelectField
        defaultValue={cl}
        placeholder="All Lean Points"
        onChange={e => setState({ ...state, cl: e ? e.value : null, currentPage: 1 }, getData)}
        options={clOptions}
        isSearchable
        isLoading={cls.length === 0}
        {...selectFieldProps}
      />
    );
  } else {
    const inputProductOptions = [
      ...new Set(inputProducts.filter(inputProduct => propNames?.includes(inputProduct.name))),
    ].map(ip => ({
      value: ip.uid,
      label: ip.name,
    }));

    targetCol = (
      <SelectField
        defaultValue={productId}
        placeholder="All Products"
        onChange={e => setState({ ...state, productId: e ? e.value : null, currentPage: 1 }, getData)}
        options={inputProductOptions}
        isSearchable
        isLoading={cls.length === 0}
        {...selectFieldProps}
      />
    );
  }
  return (
    <Card style={{ height: '100%' }}>
      <CardTitle>
        <Text as="p">Buy Activity Stream</Text>
        <CardBodyGroup gap="20px" marginLeft="30px">
          <Flex gap="20px" color="gray" flexWrap="wrap">
            <Box width="200px">
              <SelectField
                defaultValue={buyer}
                placeholder="All Buyers"
                onChange={val => setState({ ...state, buyer: val ? val.value : null, currentPage: 1 }, getData)}
                options={buyerOptions}
                {...selectFieldProps}
              />
            </Box>
            <Box width="120px">
              <SelectField
                defaultValue={proteinOptions.find(option => option.value === productType)}
                placeholder="Protein"
                onChange={val => setState({ ...state, productType: val ? val.value : null, currentPage: 1 }, getData)}
                options={proteinOptions}
                {...selectFieldProps}
              />
            </Box>
            <Box width="140px">
              <SelectField
                defaultValue={fresh_or_frozen}
                placeholder="Fresh"
                onChange={val =>
                  setState({ ...state, fresh_or_frozen: val ? val.value : null, currentPage: 1 }, getData)
                }
                options={Constants.FRESH_OR_FROZEN}
                {...selectFieldProps}
              />
            </Box>
            <Box width="240px">{targetCol}</Box>
            <Box width="240px">
              <SelectField
                defaultValue={grinderId}
                placeholder="Grinder"
                onChange={val => setState({ ...state, grinderId: val ? val.value : null, currentPage: 1 }, getData)}
                options={grinderOptions}
                {...selectFieldProps}
              />
            </Box>
            <Box width="240px">
              <SelectField
                defaultValue={packerPlantId}
                placeholder="Establishment"
                onChange={val => setState({ ...state, packerPlantId: val ? val.value : null, currentPage: 1 }, getData)}
                options={EstablimentOptions}
                {...selectFieldProps}
              />
            </Box>
          </Flex>
        </CardBodyGroup>
      </CardTitle>
      <BuyActivityPaneBody>
        {(requesting || !configs) && <Loading />}
        {error && <WatermarkText>Could not load recent buy activity</WatermarkText>}
        {!requesting && configs && !error && recentPOs.pos && renderBuyActivityTable()}
      </BuyActivityPaneBody>
    </Card>
  );
}

export default BuyActivityStream;

BuyActivityStream.propTypes = {
  token: PropTypes.string,
  dispatch: PropTypes.func,
  configs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value_str: PropTypes.string,
    })
  ),
  recentPOs: PropTypes.shape({
    pos: PropTypes.arrayOf(
      PropTypes.shape({
        orderedBy: PropTypes.string,
        productType: PropTypes.string,
        cl: PropTypes.string,
        productName: PropTypes.string,
        buyCurrency: PropTypes.string,
        buyPricePerUnit: PropTypes.number,
        volume: PropTypes.number,
        unitOfMeasure: PropTypes.string,
        packerPlantName: PropTypes.string,
        grinderName: PropTypes.string,
        internalPoNumber: PropTypes.string,
        orderedOn: PropTypes.string,
        shipmentDate: PropTypes.string,
        status: PropTypes.string,
        total: PropTypes.number,
      })
    ),
    total_count: PropTypes.number,
  }),
  recentPOFilters: PropTypes.shape({
    filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  }),
  requesting: PropTypes.bool,
  error: PropTypes.bool,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    })
  ),
  cls: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  inputProducts: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  grinders: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  packerPlants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  getRecentPOActivity: PropTypes.func,
  children: PropTypes.node,
};
