import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@chakra-ui/react';

import Icon from './Icon';

const VARIANTS = {
  filled: 'filled',
  ghost: 'ghost',
  borderless: 'borderless',
  round: 'round',
};

const SIZES = {
  lg: 'lg',
  md: 'md',
  sm: 'sm',
};

const TYPES = {
  button: 'button',
  submit: 'submit',
};

function styleButtonVariant(type) {
  const variantsByType = {
    [VARIANTS.ghost]: 'outline',
    [VARIANTS.borderless]: 'ghost',
    [VARIANTS.round]: 'round',
    [VARIANTS.filled]: 'filled',
  };
  const variant = variantsByType[type] || 'filled';

  return { variant };
}

const ButtonComponent = ({
  children = '',
  icon = '',
  iconColor = 'white',
  iconFontSize = '14px',
  iconFontWeight = 600,
  variant = VARIANTS.filled,
  size = SIZES.md,
  colorScheme = 'actionPrimary',
  type = TYPES.button,
  onClick,
  ...rest
}) => {
  const variantConfig = styleButtonVariant(variant);

  return (
    <>
      {icon ? (
        <IconButton
          {...{
            cursor: 'pointer',
            onClick,
            colorScheme,
            size,
            ...variantConfig,
            ...rest,
          }}
        >
          <Icon fontSize={iconFontSize} fontWeight={iconFontWeight} color={iconColor}>
            {icon}
          </Icon>
        </IconButton>
      ) : (
        <Button
          {...{
            cursor: 'pointer',
            onClick,
            colorScheme,
            size,
            type,
            ...variantConfig,
            ...rest,
          }}
        >
          {children}
        </Button>
      )}
    </>
  );
};

ButtonComponent.propTypes = {
  children: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconFontSize: PropTypes.string,
  iconFontWeight: PropTypes.number,
  type: PropTypes.string,
  variant: PropTypes.string,
  colorScheme: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonComponent;
