import {
  Box,
  CheckboxGroup,
  Flex,
  Grid,
  GridItem,
  Text,
  Group,
  Button,
  Input,
  VStack,
  Heading,
} from '@chakra-ui/react';
import get from 'lodash/get';
import moment from 'moment';
import React, { Component } from 'react';
import Datetime from '@/components/core/DatePicker';
import PropTypes from 'prop-types';

import SelectField from '../../basic/SelectField';
import './ColdstoreInventoryFilters.scss';
import {
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from '@/components/ui/dialog';
import { NumberInputField, NumberInputRoot } from '../../ui/number-input';
import { Checkbox } from '@/components/ui/checkbox';

const FilterSectionTitle = ({ title }) => {
  return (
    <Text as="p" fontSize="11px" marginBottom="8px">
      {title}
    </Text>
  );
};

FilterSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

const FilterActionButton = ({ children, onClick, ...rest }) => {
  return (
    <Button onClick={onClick} height="40px" width="130px" fontSize="16px" {...rest}>
      {children}
    </Button>
  );
};

FilterActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

const filterInputStyle = {
  fontSize: '12px',
  paddingLeft: '16px',
  paddingRight: '16px',
};

const selectorValueContainerStyles = {
  paddingLeft: 0,
  paddingRight: 0,
};

class ColdstoreInventoryFilters extends Component {
  showMoreThreshold = 7;

  state = {
    showedMorePackers: false,
    searchedPackers: null,

    showedMoreGrinders: false,
    searchedGrinders: null,

    searchedInputProducts: null,

    checkboxSelections: {
      grinders: [],
      packerPlants: [],
    },

    numberInputs: {
      isMinFocused: false,
      isMaxFocused: false,
    },
  };

  grinders = () =>
    this.props.grinders.filter(grinder => this.props.orders.map(order => order.grinder_uid).includes(grinder.uid));

  getGrinders = () => {
    let grinders = this.grinders();
    grinders = this.showMoreGrinders() ? grinders.splice(0, this.showMoreThreshold) : grinders;

    if (this.state.searchedGrinders) {
      grinders = grinders.filter(grinder =>
        grinder.name.toLowerCase().includes(this.state.searchedGrinders.toLowerCase())
      );
    }
    return grinders;
  };

  packerPlants = () =>
    this.props.packerPlants?.filter(packerPlant =>
      this.props.orders.map(order => order.packer_plant_id).includes(packerPlant.id)
    );

  getPackers = () => {
    let packerPlants = this.packerPlants();
    packerPlants = this.showMorePackers() ? packerPlants.splice(0, this.showMoreThreshold) : packerPlants;

    if (this.state.searchedPackers) {
      packerPlants = packerPlants?.filter(packerPlant =>
        packerPlant?.name?.toLowerCase().includes(this.state.searchedPackers.toLowerCase())
      );
    }
    return packerPlants;
  };

  getColdstores = () =>
    this.props.coldstores.filter(coldstore =>
      this.props.orders.map(order => get(order, 'coldstore_details.cold_store_id', '') === coldstore.id)
    );

  inputProducts = () =>
    this.props.inputProducts?.filter(inputProduct =>
      this.props.orders.some(order => order.lines.some(line => line.input_product_uid === inputProduct.uid))
    );

  showMorePackers = () =>
    this.state.showedMorePackers === false && this.packerPlants()?.length > this.showMoreThreshold;

  showMoreGrinders = () => this.state.showedMoreGrinders === false && this.grinders().length > this.showMoreThreshold;

  clean = () => {
    this.setState({
      showedMorePackers: false,
      searchedPackers: null,

      showedMoreGrinders: false,
      searchedGrinders: null,

      searchedInputProducts: null,
    });
    this.props.cleanFilters();
  };

  renderInput = props => {
    return (
      <div>
        <input {...props} />
      </div>
    );
  };

  handleCheckBoxSelection = (name, value) => {
    const selections = this.state.checkboxSelections[name];
    if (selections.includes(value)) {
      selections.splice(selections.indexOf(value), 1);
      this.setState(prevState => ({
        checkboxSelections: { ...prevState.checkboxSelections, [name]: selections },
      }));
    } else {
      selections.push(value);
      this.setState(prevState => ({
        checkboxSelections: { ...prevState.checkboxSelections, [name]: selections },
      }));
    }
    this.props.setFilters({ [name]: selections });
  };

  render() {
    const { setFilters, filters, onClose, showFilters } = this.props;
    const { numberInputs, searchedGrinders, searchedPackers, showedMoreGrinders, showedMorePackers } = this.state;
    const grindersOptions = this.getGrinders().map(grinder => (
      <GridItem colSpan={1} key={grinder.uid}>
        <Checkbox
          mb={0}
          value={grinder.uid}
          onChange={e => {
            this.handleCheckBoxSelection('grinders', e.target.value);
          }}
          borderColor="actionPrimary.disabled"
        >
          <Text fontSize="13px" fontWeight="medium">
            {grinder.name}
          </Text>
        </Checkbox>
      </GridItem>
    ));
    const packersOptions = this.getPackers().map(pp => (
      <GridItem colSpan={1} key={pp.id}>
        <Checkbox
          mb={0}
          value={pp.id}
          onChange={e => {
            this.handleCheckBoxSelection('packerPlants', e.target.value);
          }}
          checked={filters.packerPlants.includes(pp.id)}
          borderColor="actionPrimary.disabled"
        >
          <Text fontSize="13px" fontWeight="medium">
            {pp.name}
          </Text>
        </Checkbox>
      </GridItem>
    ));
    const coldstoreOptions = this.getColdstores().map(coldstore => ({
      value: coldstore.id,
      label: coldstore.location_name,
    }));

    const inputProductsOptions = Array.from(new Set(this.inputProducts().map(inputProduct => inputProduct.cl)))
      .sort((a, b) => {
        return a - b;
      })
      .map(cl => {
        return {
          label: cl,
          value: cl,
        };
      });

    const currenciesOptions = this.props.currencies.map(currency => ({
      value: currency.id,
      label: currency.value,
    }));

    return (
      <DialogRoot open={showFilters} onClose={onClose}>
        <DialogContent maxWidth="674px">
          <DialogHeader borderBottom="1px" borderColor="gray.200" marginBottom="39px">
            <Heading fontSize="23px" fontWeight="semibold">
              Filters
            </Heading>
            <DialogCloseTrigger asChild onClick={onClose} marginTop="8px" />
          </DialogHeader>
          <DialogBody>
            <VStack align="stretch" gap="27px">
              <Box>
                <FilterSectionTitle title="Grinder" />
                <VStack align="stretch" gap="16px">
                  {showedMoreGrinders && (
                    <Input
                      width="200px"
                      placeholder="Search grinders"
                      value={searchedGrinders}
                      onChange={e => this.setState({ searchedGrinders: e && e.target.value })}
                    />
                  )}
                  <CheckboxGroup colorScheme="actionPrimary">
                    <Grid templateColumns="repeat(2, 1fr)" rowGap="16px" columnGap="65px">
                      {grindersOptions}
                      {this.showMoreGrinders() && (
                        <GridItem colSpan={1}>
                          <Button
                            variant="link"
                            colorScheme="actionSecondary"
                            textDecoration="underline"
                            fontSize={13}
                            onClick={() => this.setState({ showedMoreGrinders: true })}
                          >
                            More
                          </Button>
                        </GridItem>
                      )}
                    </Grid>
                  </CheckboxGroup>
                </VStack>
              </Box>
              <Box>
                <FilterSectionTitle title="Used by date range" />
                <VStack align="stretch" gap="10px" width="160px">
                  <Datetime
                    id="delivery_date_start"
                    inputProps={{
                      placeholder: 'Select date',
                      className: 'form-control filter-input font-normal',
                      style: filterInputStyle,
                    }}
                    closeOnSelect
                    value={filters.usedByDateStart && moment(filters.usedByDateStart, 'YYYY-MM-DD')}
                    renderInput={props => this.renderInput(props)}
                    onChange={value => {
                      try {
                        setFilters({ usedByDateStart: value && value.format('YYYY-MM-DD') });
                      } catch (e) {
                        setFilters({ usedByDateStart: null });
                      }
                    }}
                    timeFormat={false}
                    dateFormat="YYYY-MM-DD"
                  />
                  <Datetime
                    id="delivery_date_end"
                    inputProps={{
                      placeholder: 'Select date',
                      className: 'form-control filter-input font-normal',
                      style: filterInputStyle,
                    }}
                    closeOnSelect
                    value={filters.usedByDateEnd && moment(filters.usedByDateEnd, 'YYYY-MM-DD')}
                    renderInput={props => this.renderInput(props)}
                    onChange={value => {
                      try {
                        setFilters({ usedByDateEnd: value && value.format('YYYY-MM-DD') });
                      } catch (e) {
                        setFilters({ usedByDateEnd: null });
                      }
                    }}
                    timeFormat={false}
                    dateFormat="YYYY-MM-DD"
                  />
                </VStack>
              </Box>
              <Box>
                <FilterSectionTitle title="Price" />
                <Flex gap="18px">
                  <Box width="97px">
                    <SelectField
                      onChange={value => setFilters({ currency: value })}
                      dropdownClassName="onTop"
                      placeholder="$"
                      isClearable
                      options={currenciesOptions}
                      defaultValue={filters.currency || undefined}
                      inputWidth="30px"
                      height="30px"
                      controlStyles={{
                        borderRadius: '8px',
                        borderColor: 'var(--chakra-colors-neutral-5)',
                        ...filterInputStyle,
                      }}
                      valueContainerStyles={selectorValueContainerStyles}
                    />
                  </Box>
                  <NumberInputRoot
                    value={filters.priceMin == null ? '' : filters.priceMin}
                    onChange={value => setFilters({ priceMin: value })}
                    width="97px"
                    borderRadius="8px"
                    borderColor="neutral.5"
                    onFocus={() =>
                      this.setState(prevState => ({
                        numberInputs: { ...prevState.numberInputs, isMinFocused: true },
                      }))
                    }
                    onBlur={() =>
                      this.setState(prevState => ({
                        numberInputs: { ...prevState.numberInputs, isMinFocused: false },
                      }))
                    }
                  >
                    <NumberInputField placeholder="Min." fontSize={filterInputStyle.fontSize} />
                  </NumberInputRoot>
                  <NumberInputRoot
                    value={filters.priceMax == null ? '' : filters.priceMax}
                    onChange={value => setFilters({ priceMax: value })}
                    width="97px"
                    borderRadius="8px"
                    borderColor="neutral.5"
                    onFocus={() =>
                      this.setState(prevState => ({
                        numberInputs: { ...prevState.numberInputs, isMaxFocused: true },
                      }))
                    }
                    onBlur={() =>
                      this.setState(prevState => ({
                        numberInputs: { ...prevState.numberInputs, isMaxFocused: false },
                      }))
                    }
                  >
                    <NumberInputField placeholder="Max." fontSize={filterInputStyle.fontSize} />
                  </NumberInputRoot>
                </Flex>
              </Box>
              <Box>
                <FilterSectionTitle title="CL" />
                <SelectField
                  dropdownClassName="onTop"
                  defaultValue={filters.clPoints || undefined}
                  isClearable
                  placeholder="All CLs"
                  onChange={value => setFilters({ clPoints: value })}
                  options={inputProductsOptions}
                  controlStyles={{
                    borderRadius: '8px',
                    borderColor: 'var(--chakra-colors-neutral-5)',
                    ...filterInputStyle,
                  }}
                  valueContainerStyles={selectorValueContainerStyles}
                />
              </Box>
              <Box>
                <FilterSectionTitle title="Storage date" />
                <Datetime
                  id="delivery_date"
                  inputProps={{
                    placeholder: 'Select date',
                    className: 'form-control filter-input font-normal',
                    style: filterInputStyle,
                  }}
                  closeOnSelect
                  value={filters.storageDate && moment(filters.storageDate, 'YYYY-MM-DD')}
                  renderInput={props => this.renderInput(props)}
                  onChange={value => {
                    try {
                      setFilters({ storageDate: value && value.format('YYYY-MM-DD') });
                    } catch (e) {
                      setFilters({ storageDate: null });
                    }
                  }}
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </Box>
              <Box>
                <FilterSectionTitle title="Coldstore location" />
                <SelectField
                  style={{ width: '100%' }}
                  dropdownClassName="onTop"
                  isClearable
                  placeholder="All coldstores in inventory"
                  defaultValue={filters.coldstore}
                  onChange={value => setFilters({ coldstore: value })}
                  options={coldstoreOptions}
                  controlStyles={{
                    borderRadius: '8px',
                    borderColor: 'var(--chakra-colors-neutral-5)',
                    ...filterInputStyle,
                  }}
                  valueContainerStyles={selectorValueContainerStyles}
                />
              </Box>
              <Box>
                <FilterSectionTitle title="Packers" />
                <VStack align="stretch" gap="16px">
                  {showedMorePackers && (
                    <Flex>
                      <Input
                        width="200px"
                        placeholder="Search packers"
                        value={searchedPackers}
                        onChange={e => this.setState({ searchedPackers: e && e.target.value })}
                      />
                    </Flex>
                  )}
                  <CheckboxGroup colorScheme="actionPrimary">
                    <Grid templateColumns="repeat(2, 1fr)" rowGap="16px" columnGap="65px">
                      {packersOptions}
                    </Grid>
                    {this.showMorePackers() && (
                      <GridItem colSpan={1}>
                        <Button
                          variant="link"
                          colorScheme="actionSecondary"
                          textDecoration="underline"
                          fontSize={13}
                          onClick={() => this.setState({ showedMorePackers: true })}
                        >
                          More
                        </Button>
                      </GridItem>
                    )}
                  </CheckboxGroup>
                </VStack>
              </Box>
            </VStack>
          </DialogBody>
          <DialogFooter borderTopWidth="1px" marginTop="86px">
            <Group gap="9px">
              <FilterActionButton variant="outline" onClick={this.clean} color="#6B6C7E">
                Clear All Filters
              </FilterActionButton>
              <FilterActionButton colorScheme="actionPrimary" onClick={onClose}>
                Search
              </FilterActionButton>
            </Group>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    );
  }
}

export default ColdstoreInventoryFilters;
