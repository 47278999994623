import React from 'react';
import PropTypes from 'prop-types';
import { Box, Image, Text, HStack, VStack, Heading } from '@chakra-ui/react';
import { Link } from 'react-router';
import moment from 'moment';

const ArticleSummary = ({ article = {} }) => {
  return (
    <Box width="50%" marginBottom="26px">
      <HStack gap="31px">
        <Box w="154px">
          <Image src={article.hero_url} h="113px" w="154px" />
        </Box>
        <Box flex="1">
          <Link to={`/articles/${article.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <VStack align="stretch" gap="3px" height="130px" p="10px">
              <Box maxHeight="36px">
                <Heading
                  height="100%"
                  color="accent.one.default"
                  fontSize="12px"
                  fontWeight="600"
                  casing="uppercase"
                  overflow="hidden"
                >
                  {article.title}
                </Heading>
              </Box>
              <Box h="48px" mt="9px">
                <Heading height="100%" size="16px" fontWeight="600" overflow="hidden" lineHeight="24px">
                  {article.hook}
                </Heading>
              </Box>
              <HStack gap="4px" marginTop="auto !important">
                <Text fontSize="md" color="neutral.3">{`${moment(article.published_date).format(
                  'MMM DD, YYYY'
                )} by `}</Text>
                <Text fontSize="md" color="neutral.3" fontWeight="700">
                  {' '}
                  {article.author}
                </Text>
              </HStack>
            </VStack>
          </Link>
        </Box>
      </HStack>
    </Box>
  );
};

ArticleSummary.propTypes = {
  article: PropTypes.instanceOf(Object),
};

export default ArticleSummary;
