import React, { Component } from 'react';
import { scaleTime } from 'd3-scale';
import { curveCatmullRom } from 'd3-shape';
import { XYFrame } from 'semiotic';
// LOCAL DEPS
import BuyActivityPaneBody from './BuyActivityPaneBody';
import Card, { CardTitle, CardBodyGroup, CardBodyDivider } from '../basic/Card';
import Loading from '../basic/Loading';
import { formatMonetaryValue } from '../../functions';
import Constants from '../../Constants';
import WatermarkText from './WatermarkText';
import './charts.css';
import './LiveCLs.scss';
import { Badge, Box, Center, createListCollection, Flex, HStack, Table, Text, VStack } from '@chakra-ui/react';
import { getBuyActivityGroupByValueFromConfigs, showLiveClSection } from '../../utils';
import { fetchConfigs } from '../../actions/actions_config';
import { fetchGrinders } from '../../actions/actions_grinders';
import { fetchPackerPlants } from '../../actions/actions_packer_plants';
import {
  SelectRoot,
  SelectLabel,
  SelectTrigger,
  SelectValueText,
  SelectContent,
  SelectItem,
} from '@/components/ui/select';

const fromCountries = createListCollection({
  items: [
    {
      label: 'Australia',
      value: 'Australia',
    },
    {
      label: 'Canada',
      value: 'Canada',
    },
    {
      label: 'England',
      value: 'England',
    },
    {
      label: 'Ireland',
      value: 'Ireland',
    },
    {
      label: 'New Zealand',
      value: 'New Zealand',
    },
    {
      label: 'USA',
      value: 'USA',
    },
    {
      label: 'United Kingdom',
      value: 'United Kingdom',
    },
    {
      label: 'Uruguay',
      value: 'Uruguay',
    },
  ],
});

const toCountries = createListCollection({
  items: [
    {
      label: 'Australia',
      value: 'Australia',
    },
    {
      label: 'Canada',
      value: 'Canada',
    },
    {
      label: 'China',
      value: 'China',
    },
    {
      label: 'Japan',
      value: 'Japan',
    },
    {
      label: 'Malaysia',
      value: 'Malaysia',
    },
    {
      label: 'New Zealand',
      value: 'New Zealand',
    },
    {
      label: 'Philippines',
      value: 'Philippines',
    },
    {
      label: 'South Korea',
      value: 'South Korea',
    },
    {
      label: 'Taiwan',
      value: 'Taiwan',
    },
    {
      label: 'UAE',
      value: 'UAE',
    },
    {
      label: 'USA',
      value: 'USA',
    },
  ],
});

const productTypes = createListCollection({
  items: Object.values(Constants.PRODUCT_TYPE_LIST).map(p => ({ label: p, value: p })),
});
const timeFrames = createListCollection({
  items: [
    { label: '24 hours', value: 1 },
    { label: '7 days', value: 7 },
    { label: '4 weeks', value: 28 },
    { label: '12 weeks', value: 84 },
    { label: '6 months', value: 182 },
    { label: '1 year', value: 365 },
  ],
});
const currencies = createListCollection({
  items: [
    { label: 'USD', value: 'USD' },
    { label: 'AUD', value: 'AUD' },
    { label: 'NZD', value: 'NZD' },
  ],
});
const unitsOfMeasure = createListCollection({
  items: [
    { label: 'lbs', value: 'lbs' },
    { label: 'kgs', value: 'kgs' },
  ],
});

const selectors = [
  {
    name: 'Origin',
    stateKey: 'fromCountry',
    options: fromCountries,
  },
  {
    name: 'Destination',
    stateKey: 'toCountry',
    options: toCountries,
  },
  {
    name: 'Protein',
    stateKey: 'productType',
    options: productTypes,
  },
  {
    name: 'Time Frame',
    stateKey: 'timeFrame',
    options: timeFrames,
  },
  {
    name: 'Currency',
    stateKey: 'currency',
    options: currencies,
  },
  {
    name: 'Unit of Measure',
    stateKey: 'unitOfMeasure',
    options: unitsOfMeasure,
  },
];

class LiveCLs extends Component {
  state = {
    fromCountry: 'USA',
    toCountry: 'USA',
    productType: Constants.PRODUCT_TYPE_LIST.BEEF,
    timeFrame: 28,
    currency: 'USD',
    unitOfMeasure: 'lbs',
    isChakraActive: true,
    queryType: 'cl',
  };

  componentDidMount() {
    const { token, dispatch, configs, getCurrencies, getUnitsOfMeasure } = this.props;
    this.getData();
    if (configs.length === 0) {
      dispatch(fetchConfigs(token));
    }
    dispatch(fetchGrinders(token));
    dispatch(fetchPackerPlants(token));
    getCurrencies(token);
    getUnitsOfMeasure(token);
  }

  componentDidUpdate(prevProps, prevState) {
    const { queryType } = this.state;
    if (prevState.queryType !== queryType) {
      this.getData();
    }
  }

  getData() {
    const { getLiveCLs } = this.props;
    const { fromCountry, toCountry, productType, timeFrame, currency, unitOfMeasure, queryType } = this.state;

    getLiveCLs({
      fromCountry,
      toCountry,
      productType,
      days: timeFrame,
      currency,
      unitOfMeasure,
      queryType,
    });
  }

  renderCLTable() {
    const { liveCLs } = this.props;
    const { queryType, currency } = this.state;
    if (liveCLs.length === 0) {
      return (
        <Center flex={1}>
          <WatermarkText>No purchasing activity matches those filters</WatermarkText>
        </Center>
      );
    }

    const TableHeading = ({ children, ...rest }) => (
      <Table.ColumnHeader color="black" paddingX={2} fontSize="14px" fontWeight="bold" textTransform="none" {...rest}>
        {children}
      </Table.ColumnHeader>
    );

    const TableData = ({ children, ...rest }) => (
      <Table.Cell color="gray" paddingX={2} fontWeight="bold" {...rest}>
        {children}
      </Table.Cell>
    );

    return (
      <Box flex={1} overflowY="scroll">
        <Box>
          <Table.Root size="md">
            <Table.Header>
              <Table.Row>
                <TableHeading>{queryType === 'cl' ? 'CL' : 'Product'}</TableHeading>
                <TableHeading>Last Price</TableHeading>
                <TableHeading>Min/Max</TableHeading>
                <TableHeading>Wtd Avg</TableHeading>
                <Table.ColumnHeader />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {liveCLs?.map(cl => (
                <Table.Row>
                  <TableData fontSize="16px" paddingRight={3}>
                    {cl.cl}
                  </TableData>
                  <TableData fontSize="12px">
                    {formatMonetaryValue(currency, cl.latest_price, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    })}
                  </TableData>
                  <TableData fontSize="12px">
                    {formatMonetaryValue(currency, cl.max_price, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    })}
                    <br />
                    {formatMonetaryValue(currency, cl.min_price, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    })}
                  </TableData>
                  <TableData fontSize="16px">
                    {formatMonetaryValue(currency, cl.wtd_avg_price, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    })}
                  </TableData>
                  <TableData>
                    <Box
                      padding="2px"
                      borderRadius="12px"
                      borderColor="accent.one.default"
                      borderWidth="0.7px"
                      display="inline-block"
                      position="relative"
                    >
                      <XYFrame
                        size={[94, 34]}
                        lineType={{
                          type: 'line',
                          interpolator: curveCatmullRom,
                        }}
                        lineStyle={() => ({
                          stroke: 'var(--chakra-colors-actionSecondary-500)',
                          strokeWidth: 1,
                          fill: 'none',
                        })}
                        xScaleType={scaleTime()}
                        xAccessor={d => new Date(d.date)}
                        yAccessor={d => d.wtd_avg_price}
                        yExtent={[
                          Math.min(...cl.chart_data.filter(d => d.wtd_avg_price > 0).map(d => d.wtd_avg_price)) - 0.1,
                          Math.max(...cl.chart_data.filter(d => d.wtd_avg_price > 0).map(d => d.wtd_avg_price)) + 0.1,
                        ]}
                        lines={[{ title: cl.cl, coordinates: cl.chart_data.filter(d => d.wtd_avg_price > 0) }]}
                      />
                    </Box>
                  </TableData>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </Box>
      </Box>
    );
  }

  render() {
    const { requesting, error, liveCLs, wtdAvg76, configs, grinders, packerPlants, currencies, unitsOfMeasure } =
      this.props;
    const { queryType, currency } = this.state;
    const showLiveClSectionFlag = showLiveClSection(configs);

    // GROUP
    if (packerPlants) {
      const newFromCountries = [...new Set(packerPlants?.map(p => p.origin_country))].filter(
        a => a !== null && a !== ''
      );
      selectors[0].options = createListCollection({
        items: newFromCountries.map(p => ({ label: p, value: p })),
      });
    }

    if (grinders) {
      const newToCountries = [...new Set(grinders?.map(p => p.destination_country))].filter(
        a => a !== null && a !== ''
      );
      selectors[1].options = createListCollection({
        items: newToCountries.map(g => ({ label: g, value: g })),
      });
    }

    if (currencies) {
      const newCurrencies = [...new Set(currencies?.map(p => p.value))].filter(a => a !== null && a !== '');
      selectors[4].options = createListCollection({
        items: newCurrencies.map(p => ({ label: p, value: p })),
      });
    }

    if (unitsOfMeasure) {
      const newUnitsOfMeasure = [...new Set(unitsOfMeasure?.map(u => u.name))].filter(a => a !== null && a !== '');
      selectors[5].options = createListCollection({ items: newUnitsOfMeasure.map(p => ({ label: p, value: p })) });
    }

    const queryConfigsType = getBuyActivityGroupByValueFromConfigs(configs);
    if (queryType !== queryConfigsType) {
      this.setState({ queryType: queryConfigsType });
    }

    return (
      <Card style={{ height: '100%' }}>
        <CardTitle>Recent Prices</CardTitle>
        <BuyActivityPaneBody>
          <CardBodyGroup>
            <Flex gap="20px" flexWrap="wrap">
              {selectors.map(s => {
                return (
                  <Box color="gray" key={s.stateKey}>
                    <SelectRoot
                      collection={s.options}
                      size="sm"
                      borderRadius="4px"
                      width="124px"
                      cursor="pointer"
                      value={[this.state[s.stateKey]]}
                      onValueChange={e => {
                        this.setState({ [s.stateKey]: e.value[0] }, this.getData);
                      }}
                    >
                      <SelectLabel>{s.name}:</SelectLabel>
                      <SelectTrigger>
                        <SelectValueText />
                      </SelectTrigger>
                      <SelectContent>
                        {s.options.items.map(opt => {
                          let label;
                          let value;
                          if (opt.label && opt.value) {
                            ({ value, label } = opt);
                          } else {
                            label = opt;
                            value = opt;
                          }
                          return <SelectItem key={value}>{label}</SelectItem>;
                        })}
                      </SelectContent>
                    </SelectRoot>
                  </Box>
                );
              })}
            </Flex>
          </CardBodyGroup>
          <CardBodyDivider />
          {requesting && <Loading style={{ height: 'auto', width: 'auto', margin: 0, marginTop: 20 }} />}
          {error && <WatermarkText>Could not load Recent Prices</WatermarkText>}
          <VStack height="269px" align="stretch">
            {!requesting && !error && liveCLs && this.renderCLTable()}
            <CardBodyDivider />
            {showLiveClSectionFlag && (
              <HStack justifyContent="center" alignItems="center" gap="13px">
                <VStack gap="4px" textAlign="center">
                  <Text as="p" fontWeight="medium" fontSize="25px" lineHeight="15px">
                    76
                  </Text>
                  <Text as="p" fontSize="12px" lineHeight="15px">
                    Live
                  </Text>
                </VStack>
                {!requesting && !error && wtdAvg76 ? (
                  <Badge variant="solid" width="111px" height="42px" borderRadius="4px">
                    <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
                      <Text as="p" fontWeight="medium" fontSize="21px">
                        {formatMonetaryValue(currency, wtdAvg76, {
                          maximumFractionDigits: 4,
                          minimumFractionDigits: 4,
                        })}
                      </Text>
                    </Flex>
                  </Badge>
                ) : (
                  <WatermarkText>Not enough data</WatermarkText>
                )}
              </HStack>
            )}
          </VStack>
        </BuyActivityPaneBody>
      </Card>
    );
  }
}

export default LiveCLs;
