import { Center, Table } from '@chakra-ui/react';
import ceil from 'lodash/ceil';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { getNextRolloverDate } from '../helpers';
import { Checkbox } from '@/components/ui/checkbox';
import './ColdstoreInventoryTableItem.scss';
import DatePicker from '@/components/core/DatePicker';

const computedStyle = getComputedStyle(document.body);

const ColdstoreInventoryTableItemTd = ({ children }) => {
  return (
    <Table.Cell
      verticalAlign="middle"
      borderTopColor="#B9B9B9"
      borderWidth={0}
      borderTopWidth="1px"
      textAlign="baseline"
    >
      {children}
    </Table.Cell>
  );
};

ColdstoreInventoryTableItemTd.propTypes = {
  children: PropTypes.node.isRequired,
};

const renderLine = (
  line,
  order,
  packerPlant,
  inputProduct,
  onSelectOrder,
  selected,
  onDateChange,
  orderDeliveryDates
) => {
  const poNumber = line.isMain && (
    <nobr>
      <Checkbox
        colorScheme="actionPrimary"
        onChange={() => onSelectOrder(order.id)}
        checked={selected}
        marginRight="10px"
        borderColor="actionPrimary.disabled"
      />
      {order.internal_po_number}
      {order.lines.length > 1 && '*'}
    </nobr>
  );

  const grinderPoNumber = line.isMain && order.grinder_po_number;
  let usedByDate = line.used_by_date ? moment(line.used_by_date, 'YYYY-MM-DD') : '';
  usedByDate = usedByDate && usedByDate.format('MM-DD-YYYY');

  const caseCount = get(line, 'carton_count') || '';
  const clPoint = inputProduct && inputProduct.cl;
  const coldstore = (line.isMain && get(order, 'coldstore_details.cold_store.location_name')) || null;
  const coldstoreLot = (line.isMain && get(order, 'coldstore_details.lot_numbers')?.join(', ')) || null;
  const est = line.isMain && packerPlant && packerPlant.est;
  const packerName = line.isMain && packerPlant && packerPlant.name;
  const price = ceil(line.sell_price_per_unit, 4).toFixed(4);
  const purchasingOffice = line.isMain && order.purchasing_office;
  const storageDate = getNextRolloverDate(order);
  const deliveryDate = line.isMain && (
    <Center className="table-calendar">
      <DatePicker
        id={`delivery_date_${order.id}`}
        inputProps={{ placeholder: 'Select Date' }}
        value={orderDeliveryDates[order.id] && moment(orderDeliveryDates[order.id], 'YYYY-MM-DD')}
        onChange={value => {
          onDateChange(order.id, value || null);
        }}
      />
    </Center>
  );
  const fabricationDate =
    line.earliest_production_date && moment(line.earliest_production_date, 'YYYY-MM-DD').format('MM-DD-YYYY'); // calculated property field

  const rowStyle = selected
    ? {
        backgroundColor: computedStyle.getPropertyValue('--chakra-colors-actionSecondary-active20'),
        verticalAlign: 'baseline',
      }
    : {};

  return (
    <Table.Row style={rowStyle}>
      <ColdstoreInventoryTableItemTd>{poNumber}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{grinderPoNumber}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{purchasingOffice}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{deliveryDate}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{usedByDate}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{fabricationDate}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{clPoint}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{price}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{storageDate}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{coldstore}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{packerName}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{est}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{caseCount}</ColdstoreInventoryTableItemTd>
      <ColdstoreInventoryTableItemTd>{coldstoreLot}</ColdstoreInventoryTableItemTd>
    </Table.Row>
  );
};

const ColdstoreInventoryTableItem = ({
  order,
  line,
  inputProducts,
  packerPlants,
  onSelectOrder,
  selected,
  onDateChange,
  orderDeliveryDates,
}) => {
  const packerPlant = packerPlants.find(pp => pp.id === order.packer_plant_id);
  const inputProduct = line.input_product;

  return renderLine(line, order, packerPlant, inputProduct, onSelectOrder, selected, onDateChange, orderDeliveryDates);
};

ColdstoreInventoryTableItem.propTypes = {
  inputProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  line: PropTypes.instanceOf(Object).isRequired,
  onDateChange: PropTypes.func.isRequired,
  onSelectOrder: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  orderDeliveryDates: PropTypes.instanceOf(Object).isRequired,
  packerPlants: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.bool.isRequired,
};

export default ColdstoreInventoryTableItem;
