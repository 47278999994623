import { FaChevronUp, FaSearch } from 'react-icons/fa';
import { CiSettings } from 'react-icons/ci';
import {
  Box,
  Text,
  VStack,
  Input,
  Flex,
  Button,
  HStack,
  Grid,
  Stack,
  SimpleGrid,
  GridItem,
  IconButton,
  CheckboxGroup,
  Group,
  Table,
  Heading,
  createListCollection,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router';
import axios from 'axios';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import startCase from 'lodash/startCase';
import queryString from 'query-string';
import Constants from '../../Constants';
import { getSelectedOptions, orderOptions } from './DailyTaskUtils';
import { dashboardFilters } from './searchFiltersConfig';
import Loading from '../basic/Loading';
import SelectField from '../basic/SelectField';
import isEmpty from 'lodash/isEmpty';
import RightAngleIcon from '../core/RightAngleIcon';
import DailyTaskListTableContent from './DailyTaskListTableContent';
import { PaginationBar } from '../core/PaginationComponents';
import { TaskCategories } from './TaskCategories';
import AuthGate from '../../containers/auth/AuthGate';
import { Alert } from '../ui/alert';
import { InputGroup } from '../ui/input-group';
import withRouter from '@/components/utilities/withRouter';
import { Checkbox } from '@/components/ui/checkbox';
import { Field } from '@/components/ui/field';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from '../ui/select';

const paginationOptions = createListCollection({
  items: [
    { value: 10, label: 'Show 10' },
    { value: 20, label: 'Show 20' },
    { value: 30, label: 'Show 30' },
    { value: 40, label: 'Show 40' },
    { value: 50, label: 'Show 50' },
  ],
});

export class DailyTasksDashboard extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    navigate: PropTypes.func.isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
  };

  // Reference to exception_types
  taskTypesRef = React.createRef();

  state = {
    isLoading: true,
    isApplyingSearchFilter: false,
    exceptionGroups: {},
    userOptions: [],
    grinderOptions: [],
    packer_plantOptions: [],
    user: [],
    packer_plant: [],
    grinder: [],
    orderType: {},
    po: [],
    poSearchTerm: '',
    task_type: [],
    status: [],
    product_type: [],
    purchase_office: [],
    isFiltered: true,
    isReloading: false,
    assignedCheckBoxes: {
      unassigned: false,
      assigned: false,
    },
    isListView: false,
    allPurchaseOrders: {},
    pagination: {
      page: 1,
      size: 20,
    },
    resolvedCheckBoxes: {
      resolved: false,
      unresolved: true,
    },
  };

  getCategoryList = exceptionTypes => {
    if (exceptionTypes) {
      const categorySet = new Set(exceptionTypes.map(exceptionType => exceptionType.category));
      const categoryList = Array.from(categorySet);
      return categoryList;
    }
    return [];
  };

  fetchTasksByCategory = async (categories, queryParams) => {
    const url = `${Constants.URL}exceptions?is_multi_search=true&status=OPEN&${queryString.stringify({
      category: categories,
    })}${queryParams ? `&${queryParams}` : ''}`;
    try {
      const res = await axios.get(url, this.authHeaders);
      const data = get(res, 'data', []);

      if (data) {
        const groupedExceptions = groupBy(data[0].exceptions, 'exception.exception_type.category');
        const exceptionGroups = mapKeys(groupedExceptions, (v, k) => camelCase(k));
        this.setState(prevState => {
          return {
            exceptionGroups: {
              ...prevState.exceptionGroups,
              ...exceptionGroups,
            },
          };
        });
        return data;
      }
    } finally {
      this.setState({
        isLoading: false,
        isReloading: false,
        isApplyingSearchFilter: false,
      });
    }
  };

  fetchPurchaseOrders = async queryParams => {
    try {
      const url = `${Constants.URL}purchase_orders?is_multi_search=true${queryParams ? `&${queryParams}` : ''}`;
      const res = await axios.get(url, this.authHeaders);
      return get(res, 'data', []);
    } catch (error) {
      this.setState({
        isLoading: false,
        isReloading: false,
        isApplyingSearchFilter: false,
      });
      return [];
    }
  };

  async componentDidMount() {
    const { user, navigate, location } = this.props;
    const { pagination } = this.state;
    const { page, size } = pagination;
    const { pathname, search } = location;

    const parsedFilters = queryString.parse(search);
    const appliedFilters = { ...parsedFilters };

    // Determine default order type filter
    const defaultView = 'all';
    const orderTypeValue = parsedFilters.order_type || defaultView;
    const orderType = {
      label: startCase(orderTypeValue),
      value: orderTypeValue,
    };
    appliedFilters.order_type = orderType.value;

    // Determine user filter
    const loggedInUser = {
      value: user.id,
      label: `${user.first_name} ${user.last_name}`,
    };
    appliedFilters.user = parsedFilters.user || loggedInUser.value;

    appliedFilters.page = page;
    appliedFilters.size = size;
    appliedFilters.resolved = !this.state.resolvedCheckBoxes.unresolved;

    // Update Url
    const appliedFiltersQuery = queryString.stringify(appliedFilters);
    navigate(`${pathname}?${appliedFiltersQuery}`);

    try {
      // Get dropdown data
      const usersReq = axios.get(`${Constants.URL}users`, this.authHeaders);
      const grindersReq = axios.get(`${Constants.URL}grinders`, this.authHeaders);
      const packersReq = axios.get(`${Constants.URL}packer_plants`, this.authHeaders);
      const taskTypesReq = axios.get(`${Constants.URL}exception_types`, this.authHeaders);
      const [users, packerPlants, grinders, taskTypes] = await Promise.all([
        usersReq,
        packersReq,
        grindersReq,
        taskTypesReq,
      ]);

      // User options:
      const userOptions = get(users, 'data', [])
        .filter(usr => !usr.delisted)
        .map(usr => ({
          value: usr.id,
          label: `${usr.first_name} ${usr.last_name}`.trim(),
        }));
      const sortedUsers = orderOptions(userOptions);
      const selectedUsers = getSelectedOptions(appliedFilters.user, sortedUsers);
      // Grinder Options:
      const grinderOptions = get(grinders, 'data', [])
        .filter(grinder => !grinder.delisted)
        .map(grinder => ({
          value: grinder.uid,
          label: grinder.name,
        }));
      const sortedGrinders = orderOptions(grinderOptions);
      const selectedGrinders = getSelectedOptions(appliedFilters.grinder, sortedGrinders);

      // Packer Options:
      const packerPlantOptions = get(packerPlants, 'data', [])
        .filter(packerPlant => !packerPlant.delisted)
        .map(packerPlant => ({
          value: packerPlant.id,
          label: packerPlant.name,
        }));
      const sortedPackers = orderOptions(packerPlantOptions);
      const selectedPackers = getSelectedOptions(appliedFilters.packerPlant, sortedPackers);

      // Exception Type Options:
      const taskTypeOptions = get(taskTypes, 'data', []).map(taskTypeOption => ({
        value: taskTypeOption.name,
        label: taskTypeOption.label,
      }));
      const sortedTaskTypes = orderOptions(taskTypeOptions);
      const selectedTaskTypes = getSelectedOptions(appliedFilters.taskType, sortedTaskTypes);
      this.taskTypesRef.current = groupBy(get(taskTypes, 'data', []), 'category');

      // Common new state:
      const commonNewState = {
        orderType,
        isLoading: false,
        packer_plant: selectedPackers,
        grinder: selectedGrinders,
        task_type: selectedTaskTypes,
        user: selectedUsers,
        userOptions: [...sortedUsers],
        grinderOptions: sortedGrinders,
        packer_plantOptions: sortedPackers,
        task_typeOptions: sortedTaskTypes,
        appliedFiltersQuery,
        appliedFilters,
        isListView: appliedFilters.view === 'list',
      };

      // Fetch exceptions by category
      switch (defaultView) {
        case 'international': {
          await this.fetchTasksByCategory('international', appliedFiltersQuery);
          break;
        }
        case 'domestic': {
          await this.fetchTasksByCategory('domestic', appliedFiltersQuery);
          break;
        }
        case 'all':
        default: {
          const categories = [];
          Object.keys(this.taskTypesRef.current).forEach(category => {
            categories.push(category);
          });
          await this.fetchTasksByCategory(categories, appliedFiltersQuery);
          break;
        }
      }

      const allPurchaseOrders = await this.fetchPurchaseOrders(appliedFiltersQuery);
      if (allPurchaseOrders.purchase_orders) {
        allPurchaseOrders.purchase_orders.sort();
      }

      this.setState(prevState => ({
        ...prevState,
        ...commonNewState,
        allPurchaseOrders,
      }));

      return {};
    } catch (error) {
      this.setState({
        isLoading: false,
        isApplyingSearchFilter: false,
        isReloading: false,
      });
    }
    return null;
  }

  async componentDidUpdate(prevProps, prevState) {
    const { orderType, isReloading, isApplyingSearchFilter } = this.state;

    if (isApplyingSearchFilter !== prevState.isApplyingSearchFilter || isReloading !== prevState.isReloading) {
      const { appliedFiltersQuery } = this.state;

      const allPurchaseOrders = await this.fetchPurchaseOrders(appliedFiltersQuery);
      if (allPurchaseOrders.purchase_orders) {
        allPurchaseOrders.purchase_orders.sort();
      }

      // Fetch exceptions by category
      switch (orderType.value) {
        case 'international': {
          await this.fetchTasksByCategory('international', appliedFiltersQuery);
          this.setState(pState => ({
            ...pState,
            allPurchaseOrders,
            isLoading: false,
            isApplyingSearchFilter: false,
            isReloading: false,
          }));
          break;
        }
        case 'domestic': {
          await this.fetchTasksByCategory('domestic', appliedFiltersQuery);
          this.setState(prState => ({
            ...prState,
            allPurchaseOrders,
            isLoading: false,
            isFiltered: true,
            isReloading: false,
            isApplyingSearchFilter: false,
          }));
          break;
        }
        case 'all':
        default: {
          const categories = [];
          Object.keys(this.taskTypesRef.current).forEach(category => {
            categories.push(category);
          });
          await this.fetchTasksByCategory(categories, appliedFiltersQuery);
          this.setState(prvState => ({
            ...prvState,
            allPurchaseOrders,
            isLoading: false,
            isReloading: false,
            isApplyingSearchFilter: false,
          }));
          break;
        }
      }
    }
    return null;
  }

  handleFilterChange = (selected, fieldName) => {
    const selectedOptions = Array.isArray(selected) ? selected : [selected];

    const commonNewState = {
      ...this.state,
      [fieldName]: selected,
      isFiltered: true,
      appliedFilters: {
        ...this.state.appliedFilters,
        [fieldName]: selectedOptions.map(opt => opt.value),
      },
    };

    this.setState(commonNewState);
  };

  handleCheckboxChange = checkBoxName => {
    let appliedFilters = {
      ...this.state.appliedFilters,
    };

    const assignedValue = this.state.assignedCheckBoxes.assigned;
    const unassignedValue = this.state.assignedCheckBoxes.unassigned;
    if (assignedValue === !unassignedValue || !assignedValue === unassignedValue) {
      delete appliedFilters.assigned;
    } else if (checkBoxName === 'unassigned') {
      appliedFilters = {
        ...this.state.appliedFilters,
        assigned: unassignedValue,
      };
    } else {
      appliedFilters = {
        ...this.state.appliedFilters,
        assigned: !assignedValue,
      };
    }

    const commonNewState = {
      ...this.state,
      isFiltered: true,
      appliedFilters: {
        ...appliedFilters,
      },
      assignedCheckBoxes: {
        ...this.state.assignedCheckBoxes,
        [checkBoxName]: !this.state.assignedCheckBoxes[checkBoxName],
      },
    };

    this.setState(commonNewState);
  };

  handleResolveFilterCheckboxChange = checkBoxName => {
    const { navigate, location } = this.props;
    const { pathname, search } = location;

    const parsed = queryString.parse(search);
    let { appliedFilters } = this.state;

    const resolvedValue = this.state.resolvedCheckBoxes.resolved;
    const unresolvedValue = this.state.resolvedCheckBoxes.unresolved;
    if (resolvedValue === !unresolvedValue || !resolvedValue === unresolvedValue) {
      appliedFilters = {
        ...parsed,
      };
      delete appliedFilters.resolved;
    } else if (checkBoxName === 'unresolved') {
      appliedFilters = {
        ...parsed,
        resolved: unresolvedValue,
      };
    } else {
      appliedFilters = {
        ...parsed,
        resolved: !resolvedValue,
      };
    }
    const updatedQueryString = queryString.stringify(appliedFilters);
    navigate(`${pathname}?${updatedQueryString}`);

    const commonNewState = {
      ...this.state,
      isApplyingSearchFilter: true,
      isFiltered: true,
      appliedFilters,
      appliedFiltersQuery: updatedQueryString,
      resolvedCheckBoxes: {
        ...this.state.resolvedCheckBoxes,
        [checkBoxName]: !this.state.resolvedCheckBoxes[checkBoxName],
      },
    };

    this.setState(commonNewState);
  };

  handleClearFilter = () => {
    const { navigate, location } = this.props;
    const { pathname } = location;

    navigate(`${pathname}`);
    this.setState({
      po: [],
      user: [],
      packerPlant: [],
      grinder: [],
      task_type: [],
      status: [],
      product_type: [],
      purchase_office: [],
      poSearchTerm: '',
      isFiltered: false,
      isReloading: true,
      appliedFiltersQuery: '',
      assignedCheckBoxes: {
        unassigned: false,
        assigned: false,
      },
      pagination: {
        page: 1,
        size: 20,
      },
      appliedFilters: {},
    });
  };

  handlePOSearch = () => {
    const { poSearchTerm: text } = this.state;
    const { navigate, location } = this.props;
    const { pathname } = location;
    // const parsed = queryString.parse(search);
    const appliedFilters = {
      // ...parsed,
      ...this.state.appliedFilters,
      ...(!isEmpty(text) && { po_number: [text] }),
    };
    const updatedQueryString = queryString.stringify(appliedFilters);

    navigate(`${pathname}?${updatedQueryString}`);
    this.setState({
      po: [text],
      isApplyingSearchFilter: true,
      appliedFiltersQuery: updatedQueryString,
      allPurchaseOrders: {},
      exceptionGroups: {},
    });
  };

  handlePageChange = (fieldName, value) => {
    const { navigate, location } = this.props;
    const { pathname, search } = location;
    const parsed = queryString.parse(search);
    const appliedFilters = {
      ...parsed,
      [fieldName]: value,
    };
    const updatedQueryString = queryString.stringify(appliedFilters);

    const commonNewState = {
      ...this.state,
      isApplyingSearchFilter: true,
      isFiltered: true,
      appliedFilters: {
        ...this.state.appliedFilters,
        [fieldName]: value,
      },
      appliedFiltersQuery: updatedQueryString,
      pagination: { ...this.state.pagination, [fieldName]: value },
    };
    this.setState(commonNewState);

    navigate(`${pathname}?${updatedQueryString}`);
  };

  getExceptionGroupsData = viewFilter => {
    const { exceptionGroups } = this.state;
    const exceptionTypes = this.taskTypesRef.current[viewFilter];
    const categoryList = this.getCategoryList(exceptionTypes).filter(category => category);
    const exceptionData = categoryList.map(category => {
      return {
        label: startCase(category.replace('_', ' ')),
        data: exceptionGroups[camelCase(category)] || [],
        count: exceptionGroups[camelCase(category)]?.length || 0,
      };
    });
    return exceptionData;
  };

  getExceptionGroups = () => {
    const { getExceptionGroupsData } = this;
    const { orderType } = this.state;
    const showInternationalView = orderType.value === 'international';
    const showDomesticView = orderType.value === 'domestic';
    const showAllView = orderType.value === 'all';
    const data = [];
    if (showInternationalView) {
      data.push(...getExceptionGroupsData(showInternationalView));
    }
    if (showDomesticView) {
      data.push(...getExceptionGroupsData(showDomesticView));
    }
    if (showAllView) {
      Object.keys(this.taskTypesRef.current).forEach(category => {
        data.push(...getExceptionGroupsData(category));
      });
    }
    return data.filter(group => group.count > 0);
  };

  handleTaskPatch = async (exceptionId, data) => {
    try {
      const response = await axios.patch(`${Constants.URL}exceptions/${exceptionId}`, data, this.authHeaders);

      const commonNewState = {
        ...this.state,
        isReloading: true,
      };
      this.setState(commonNewState);
      return response;
    } catch (e) {
      return null;
    }
  };

  toggleListView = () => this.setState(prevState => ({ isListView: !prevState.isListView }));

  render() {
    const {
      isLoading,
      isReloading,
      assignedCheckBoxes,
      isFiltered,
      isListView,
      isApplyingSearchFilter,
      poSearchTerm,
      allPurchaseOrders,
      pagination,
      userOptions,
    } = this.state;

    return (
      <VStack align="stretch" gap="28px">
        <Flex alignItems="center">
          <Heading fontSize="18px">Daily Tasks</Heading>
          <Group ml="auto">
            <AuthGate requiredPermissions={['admin', 'exceptions_read']}>
              <Link to="/daily-tasks-admin" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button
                  variant="solid"
                  size="lg"
                  ml="auto"
                  fontWeight="600"
                  fontSize="16px"
                  onClick={this.toggleListView}
                >
                  Admin
                  <CiSettings />
                </Button>
              </Link>
            </AuthGate>
            <Button
              variant={isListView ? 'outline' : 'solid'}
              colorScheme="actionPrimary"
              size="lg"
              fontWeight="600"
              fontSize="16px"
              onClick={this.toggleListView}
            >
              {isListView ? 'Dashboard' : 'List View'}
              {!isListView && <i className="cfc-icon icon-ui-bullets" />}
            </Button>
          </Group>
        </Flex>
        <VStack
          align="stretch"
          paddingTop="13px"
          paddingBottom="52px"
          paddingX="27px"
          boxShadow="md"
          borderRadius="15px"
          backgroundColor="card.default"
          gap="30px"
        >
          <Flex alignItems="center" width="100%">
            <RightAngleIcon />
            <Heading fontSize="15px" marginLeft="10px">
              Search Filters
            </Heading>
            <IconButton ml="auto" aria-label="Collapse" bgColor="inherit">
              <FaChevronUp />
            </IconButton>
          </Flex>

          <SimpleGrid columns={{ sm: 1, md: 4 }} gapY="24px" gapX="35px">
            {isLoading ? (
              <Box minW="100%">
                <Loading />
              </Box>
            ) : (
              <>
                <GridItem colSpan={1}>
                  <HStack gap={0} mr="auto" justifyContent="space-between">
                    <Text fontWeight="medium" width="111px">
                      By PO#
                    </Text>
                    <InputGroup
                      alignItems="center"
                      width="100%"
                      height="40px"
                      endElement={<FaSearch color="gray.300" />}
                    >
                      <Input
                        placeholder="Search"
                        fontSize="16px"
                        bgColor="white"
                        value={poSearchTerm}
                        onChange={e => this.setState({ poSearchTerm: e.target.value, isFiltered: true })}
                      />
                    </InputGroup>
                  </HStack>
                </GridItem>
                <GridItem colSpan={1} colStart={4}>
                  <CheckboxGroup colorScheme="actionSecondary">
                    <HStack gap={5} alignItems="center" justifyContent="end">
                      <Checkbox
                        mb={0}
                        checked={assignedCheckBoxes.assigned}
                        onChange={() => this.handleCheckboxChange('assigned')}
                      >
                        Assigned
                      </Checkbox>
                      <Checkbox
                        mb={0}
                        checked={assignedCheckBoxes.unassigned}
                        onChange={() => this.handleCheckboxChange('unassigned')}
                      >
                        Unassigned
                      </Checkbox>
                    </HStack>
                  </CheckboxGroup>
                </GridItem>
                {dashboardFilters.map(sf => (
                  <GridItem colSpan={1} key={sf.name}>
                    <Field>
                      <SelectField
                        key={sf.name}
                        options={sf.options || this.state[`${sf.name}Options`] || []}
                        onChange={this.handleFilterChange}
                        {...sf}
                        containerWidth="100%"
                        labelProps={{ fontSize: '14px', width: '111px' }}
                        asHorizontalStack
                        containerStackProps={{
                          align: 'center',
                          justifyContent: 'space-between',
                        }}
                        value={this.state[sf.name]}
                      />
                    </Field>
                  </GridItem>
                ))}
              </>
            )}
          </SimpleGrid>
          <Group colorScheme="actionSecondary" gap="6" float="right" width="100%" display="block" textAlign="right">
            {isFiltered && (
              <Button
                size="lg"
                variant="link"
                _hover={{ textDecoration: 'none' }}
                fontSize="16px"
                onClick={() => this.handleClearFilter()}
              >
                Clear Filters
              </Button>
            )}
            <Button
              variant="solid"
              size="lg"
              width="166px"
              disabled={isApplyingSearchFilter || isReloading}
              fontSize="16px"
              onClick={() => this.handlePOSearch()}
            >
              Search POs
            </Button>
          </Group>
        </VStack>

        {!isLoading && (
          <Stack width="fit-content" alignSelf="start">
            <Alert
              status="info"
              // variant="left-accent"
              title="Daily Task exceptions created and shown are only for last 3 months by default"
            />
          </Stack>
        )}

        {isListView ? (
          <Box>
            <VStack
              align="stretch"
              paddingY="13px"
              paddingLeft="27px"
              paddingRight="30px"
              boxShadow="md"
              borderRadius="15px"
              backgroundColor="card.default"
              gap="30px"
            >
              <Flex alignItems="center" width="100%">
                <RightAngleIcon />
                <Heading fontSize="15px" marginLeft="10px">
                  Tasks List
                </Heading>
                <CheckboxGroup colorScheme="actionSecondary" marginLeft="auto">
                  <HStack gap="5" alignItems="center" marginLeft="auto">
                    <Checkbox
                      checked={this.state.resolvedCheckBoxes.resolved === true}
                      mb={0}
                      onChange={() => this.handleResolveFilterCheckboxChange('resolved')}
                    >
                      <Text fontWeight="medium">Show Resolved Tasks</Text>
                    </Checkbox>
                    <Checkbox
                      checked={this.state.resolvedCheckBoxes.unresolved === true}
                      mb={0}
                      onChange={() => this.handleResolveFilterCheckboxChange('unresolved')}
                    >
                      <Text fontWeight="medium">Show Unresolved Tasks</Text>
                    </Checkbox>
                  </HStack>
                </CheckboxGroup>
              </Flex>
              <Box bgColor="white">
                <Table.Root variant="simple">
                  <Table.Header backgroundColor="card.default">
                    <Table.Row>
                      <Table.ColumnHeader>
                        <Text fontSize="12px">PO #</Text>
                      </Table.ColumnHeader>
                      <Table.ColumnHeader>
                        <Text as="p" fontSize="12px">
                          INTERNAL PO #
                        </Text>
                      </Table.ColumnHeader>
                      <Table.ColumnHeader>
                        <Text as="p" fontSize="12px">
                          GRINDER
                        </Text>
                      </Table.ColumnHeader>
                      <Table.ColumnHeader>
                        <Text as="p" fontSize="12px">
                          PACKER
                        </Text>
                      </Table.ColumnHeader>
                      <Table.ColumnHeader>
                        <Text as="p" fontSize="12px">
                          TASKS FOUND
                        </Text>
                      </Table.ColumnHeader>
                      <Table.ColumnHeader textAlign="center">
                        <Text as="p" fontSize="12px">
                          USERS
                        </Text>
                      </Table.ColumnHeader>
                      <Table.ColumnHeader textAlign="center">
                        <Text as="p" fontSize="12px">
                          RESOLVE
                        </Text>
                      </Table.ColumnHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {isApplyingSearchFilter ? (
                      <Table.Row>
                        <Table.Cell colSpan={7}>
                          <Flex width="100%" height="200px" justifyContent="center" alignItems="center">
                            <Loading />
                          </Flex>
                        </Table.Cell>
                      </Table.Row>
                    ) : (
                      <DailyTaskListTableContent
                        token={this.props.token}
                        allPurchaseOrders={allPurchaseOrders}
                        onResolveClick={async (exceptionId, data) => {
                          await this.handleTaskPatch(exceptionId, data);
                          this.setState({});
                        }}
                        userOptions={userOptions}
                        onAssign={async (exceptionId, data) => {
                          await this.handleTaskPatch(exceptionId, data);
                          this.setState({});
                        }}
                      />
                    )}
                  </Table.Body>
                </Table.Root>
              </Box>
              {!isEmpty(allPurchaseOrders.pagination) && allPurchaseOrders.pagination.pages > 0 && (
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={11}>
                    <PaginationBar
                      pages={allPurchaseOrders.pagination.pages}
                      currentPage={pagination.page}
                      onPageChange={page => this.handlePageChange('page', page)}
                      isApplyingSearchFilter={isApplyingSearchFilter}
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Box width="119px">
                      <SelectRoot
                        collection={paginationOptions}
                        width="320px"
                        onValueChange={e => this.handlePageChange('size', e.value[0])}
                      >
                        <SelectTrigger>
                          <SelectValueText placeholder="Show 20" />
                        </SelectTrigger>
                        <SelectContent>
                          {paginationOptions.items.map(opt => (
                            <SelectItem item={opt} key={opt.value}>
                              {opt.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </SelectRoot>
                    </Box>
                  </GridItem>
                </Grid>
              )}
            </VStack>
          </Box>
        ) : (
          <SimpleGrid columns={{ sm: 1, md: 4 }} gap="37px" minChildWidth="286px" mt="48px">
            {isApplyingSearchFilter || isReloading ? (
              <Box minW="100%">
                <Loading />
              </Box>
            ) : (
              <TaskCategories groups={this.getExceptionGroups()} onViewAll={this.toggleListView} />
            )}
          </SimpleGrid>
        )}
      </VStack>
    );
  }
}

export default withRouter(DailyTasksDashboard);
