import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@chakra-ui/react';
import ArticleRegionCard from './ArticleRegionCard';

const ArticleRegionCardList = ({ articleRegions = [] }) => {
  return (
    <Grid gap="50px" templateColumns="repeat(4, 1fr)" paddingInline="80px">
      {articleRegions.map(article => (
        <ArticleRegionCard {...article} />
      ))}
    </Grid>
  );
};

ArticleRegionCardList.propTypes = {
  articleRegions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ),
};

export default ArticleRegionCardList;
