import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/react';
import { Tag } from '@/components/ui/tag';

const CategoryTag = ({ category, label }) => {
  const categories = {
    purchase_unshipped: {
      label: 'Purchase Unshipped',
      bgColor: '#2C7FFF',
      color: '#FFFFFF',
    },
    on_the_water: {
      label: 'On The Water',
      bgColor: '#319795',
      color: '#FFFFFF',
    },
    inventory: {
      label: 'Inventory',
      bgColor: '#805AD5',
      color: '#FFFFFF',
    },
    delivery: {
      label: 'Scheduled Delivery',
      bgColor: '#FBD38D',
      color: '#000000',
    },
  };
  return (
    <Tag variant="solid" height="25px" paddingY="2px" paddingX="8px" borderRadius="6px" {...categories[category]}>
      <Text as="p" fontSize="14px" fontWeight="medium" lineHeight="20px">
        {label}
      </Text>
    </Tag>
  );
};

CategoryTag.propTypes = {
  category: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CategoryTag;
