import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Tabs } from '@chakra-ui/react';

const ColdStoreManagementTabList = ({ handleNavigation, tabs }) => {
  return (
    <Tabs.List borderBottomWidth="0px">
      {tabs.map((tab, index) => (
        <Tabs.Trigger
          value={index}
          _focus={{ outline: 'none' }}
          _selected={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
          _hover={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
          width="162px"
          padding="0px"
          height="103px"
          marginRight="10px"
          justifyContent="center"
          onClick={() => handleNavigation(tab.type, tab.name)}
        >
          <Heading fontSize="14px" fontWeight="normal">
            {tab.label}
          </Heading>
        </Tabs.Trigger>
      ))}
    </Tabs.List>
  );
};

ColdStoreManagementTabList.propTypes = {
  handleNavigation: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.objectOf({
      type: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default ColdStoreManagementTabList;
