import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { Component } from 'react';
import Constants from '../../../Constants';
import { downloadFromS3Link } from '../../../helpers/downloads';
import Loading from '../../basic/Loading';
import { groupByMultiple, isOrderCompleted } from '../helpers';
import EmailModal from './EmailModal';
import './GrinderRelease.scss';
import GrinderTable from './GrinderTable';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Box, Image, Text, HStack, Flex, Button as ChakraButton, Separator } from '@chakra-ui/react';
import { Alert } from '../../ui/alert';

class GrinderRelease extends Component {
  constructor(props) {
    super(props);

    const ordersMap = this.generateOrdersMap();

    this.state = {
      ordersMap,
      isLoading: false,
      selectedOrders: {},
      isEmailModalOpen: false,
      isEditingOrder: false,
      editedOrder: {},
      emailModalData: {},
      previewOrders: {
        url: null,
        name: null,
      },
      previewBtnId: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // for fetching preview pdf
    if (this.props.previewOrders && this.state.isLoading && prevProps.previewOrders !== this.props.previewOrders) {
      this.setState(
        {
          previewOrders: this.props.previewOrders,
          isLoading: false,
        },
        () => downloadFromS3Link(this.props.previewOrders.url)
      );
    } else if (this.props.previewOrders && prevState.previewOrders !== this.props.previewOrders) {
      this.setState({
        previewOrders: this.props.previewOrders,
      });
    }

    if (this.props.isColdstoreEmailSent && this.state.isEmailModalOpen && !this.state.isEmailClicked) {
      this.setState({ isEmailModalOpen: false });
    }

    if (prevProps.orders !== this.props.orders) {
      const ordersMap = this.generateOrdersMap();
      this.setState({
        ordersMap,
      });
    }
  }

  generateOrdersMap = () => {
    return this.props.orders.reduce((agg, order) => {
      agg[order.id] = {
        delivery_date: order.delivery_date,
        transporter_id: order.transporter_id,
        scheduled_delivery_time: order.scheduled_delivery_time,
        shipment_date: order.shipment_date,
        null_delivery_time: !(order.null_delivery_time || order.scheduled_delivery_time),
        id: order.id,
        // required for EmailModal
        internal_po_number: order.internal_po_number,
      };
      return agg;
    }, {});
  };

  handleValueChange = (id, key, value) => {
    let pValue = value === undefined || value === '' ? null : value;

    if (key && key.includes('_date') && !moment(pValue).isValid()) {
      pValue = null;
    }

    const newOrdersMap = { ...this.state.ordersMap };
    newOrdersMap[id][key] = pValue;
    this.setState({ ordersMap: newOrdersMap });

    if (key === 'null_delivery_time') {
      if (pValue === true) {
        this.handleValueChange(id, 'scheduled_delivery_time', null);
      }
      return;
    }

    /* check to avoid incomplete fetch requests 
      an order in release tab cannot have half filled 
      fields */
    if (isOrderCompleted(this.state.ordersMap)) {
      this.props.patchOrder(id, {
        [key]: pValue,
      });
    }
  };

  handleOrderSelection = (order, value) => {
    const { selectedOrders, ordersMap } = this.state;
    const selectedOrdersDeepCopy = cloneDeep(selectedOrders);
    if (value) {
      selectedOrdersDeepCopy[order.id] = ordersMap[order.id];
    } else {
      delete selectedOrdersDeepCopy[order.id];
    }
    this.setState({
      selectedOrders: selectedOrdersDeepCopy,
    });
  };

  handleEditOrderRow = (order, isEditable) => {
    this.setState({
      isEditingOrder: isEditable ? true : false,
      editedOrder: order,
    });
  };

  render() {
    return (
      <div className="grinder-release">
        {this.renderCompleteLoads()}
        {this.renderReleasedLoads()}
      </div>
    );
  }

  /* without email, PDF content is previewed */
  previewPdf = orders => {
    const ordersCopy = cloneDeep(orders);
    const previewBtnId = Array.isArray(ordersCopy) ? ordersCopy[0].id : ordersCopy.id;
    this.setState({ isLoading: true, previewBtnId });
    this.props.getColdStoreReleasePDF(orders, this.props.token);
  };

  renderReleasedLoads = () => {
    const releasedLoads = this.props.orders.filter(
      order => order.status === Constants.ORDER_STATUSES.COLDSTORE_RELEASED
    );

    if (releasedLoads.length === 0) return null;

    const groupedOrders = groupByMultiple(releasedLoads, item => [
      item.transporter_id,
      item.coldstore_details ? item.coldstore_details.cold_store_id : 0,
    ]);
    const { transporters } = this.props;

    return (
      <Box borderLeftWidth="2px" borderColor="gray.50">
        {groupedOrders.map((orders, i) => {
          const transporter = transporters.find(transporter => transporter.id === orders[0].transporter_id);
          const coldstore = orders[0].coldstore_details?.cold_store;

          return (
            <Box key={i}>
              <HStack
                gap="16px"
                separator={<Separator borderColor="black" orientation="vertical" height='24px'/>}
                paddingLeft="22px"
                backgroundColor="nav.horizontalBandBgColor"
                paddingY="7px"
              >
                <HStack gap="6px" paddingTop="8px" paddingBottom="2px">
                  <Image src="img/self_service_reports_images/logistic-small.svg" height="20px" />
                  <Text as="p" fontWeight="bold">
                    {coldstore ? coldstore.location_name : ''}
                  </Text>
                </HStack>
                <HStack gap="6px" paddingTop="8px" paddingBottom="2px">
                  <Image src="img/self_service_reports_images/truck-small.svg" height="18px" />
                  <Text as="p" fontWeight="bold">
                    {transporter && transporter.name}
                  </Text>
                </HStack>
              </HStack>
              <GrinderTable
                orders={orders}
                patchOrder={this.props.patchOrder}
                transporters={this.props.transporters}
                handleValueChange={this.handleValueChange}
                ordersMap={this.state.ordersMap}
                prevColdstoreStatus={Constants.ORDER_STATUSES.PENDING_COLDSTORE_RELEASE}
                isEditable={this.state.isEditingOrder}
                onToggleEditOrderRow={this.handleEditOrderRow}
                editedOrder={this.state.editedOrder}
                token={this.props.token}
                dispatch={this.props.dispatch}
                previewOrders={this.state.previewOrders}
                notifying_action_groups={this.props.notifying_action_groups}
                coldstoreSendEmailError={this.props.coldstoreSendEmailError}
                clearColdStoreEmailModal={this.props.clearColdStoreEmailModal}
                isColdstoreEmailSent={this.props.isColdstoreEmailSent}
                internalPoLabel={this.props.internalPoLabel}
                user={this.props.user}
              />
              <Flex justifyContent="space-between" marginY="20px" marginX="16px">
                {this.state.isLoading ? (
                  <Loading />
                ) : (
                  <ChakraButton
                    colorScheme="actionPrimary"
                    height="48px"
                    width="223px"
                    fontSize="16px"
                    onClick={() => {
                      this.previewPdf(orders);
                    }}
                  >
                    <MdOutlineKeyboardArrowDown />
                    <Text as="p" fontSize="md">
                      Download Released Loads
                    </Text>
                  </ChakraButton>
                )}
              </Flex>
            </Box>
          );
        })}
      </Box>
    );
  };

  renderCompleteLoads = () => {
    const completeOrders = this.props.orders.filter(
      order => order.status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_RELEASE
    );
    if (completeOrders.length === 0) return null;

    const groupedOrders = groupByMultiple(completeOrders, item => [
      item.transporter_id,
      item.coldstore_details ? item.coldstore_details.cold_store_id : 0,
    ]);
    const { transporters, grinders } = this.props;

    return (
      <Box>
        {groupedOrders.map((orders, i) => {
          const transporter = transporters.find(transporter => transporter.id === orders[0].transporter_id);
          const coldstore = orders[0].coldstore_details ? orders[0].coldstore_details.cold_store : null;
          const grinder = grinders.find(grinder => grinder.uid === orders[0].grinder_uid);
          const areOrdersInCompleteState = orders.every(order => isOrderCompleted(this.state.ordersMap[order.id]));
          const showPreviewBtn = Array.isArray(orders)
            ? orders.map(o => o.id).includes(this.state.previewBtnId)
            : orders[0].id === this.state.previewBtnId;

          return (
            <Box key={i}>
              <HStack
                gap="16px"
                separator={<Separator borderColor="black" orientation="vertical" height='24px'/>}
                paddingLeft="22px"
                backgroundColor="nav.horizontalBandBgColor"
                paddingY="7px"
              >
                <HStack gap="6px" paddingTop="8px" paddingBottom="2px">
                  <Image src="img/self_service_reports_images/logistic-small.svg" height="20px" />
                  <Text as="p" fontWeight="bold">
                    {coldstore ? coldstore.location_name : ''}
                  </Text>
                </HStack>
                <HStack gap="6px" paddingTop="8px" paddingBottom="2px">
                  <Image src="img/self_service_reports_images/truck-small.svg" height="18px" />
                  <Text as="p" fontWeight="bold">
                    {transporter && transporter.name}
                  </Text>
                </HStack>
              </HStack>
              <GrinderTable
                orders={orders}
                patchOrder={this.props.patchOrder}
                transporters={this.props.transporters}
                handleValueChange={this.handleValueChange}
                ordersMap={this.state.ordersMap}
                selectedOrders={this.state.selectedOrders}
                handleOrderSelection={this.handleOrderSelection}
                prevColdstoreStatus={Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE}
                isEditable={this.state.isEditingOrder}
                onEditOrderRow={this.handleEditOrderRow}
                token={this.props.token}
                dispatch={this.props.dispatch}
                previewOrders={this.state.previewOrders}
                notifying_action_groups={this.props.notifying_action_groups}
                coldstoreSendEmailError={this.props.coldstoreSendEmailError}
                clearColdStoreEmailModal={this.props.clearColdStoreEmailModal}
                isColdstoreEmailSent={this.props.isColdstoreEmailSent}
                internalPoLabel={this.props.internalPoLabel}
                user={this.props.user}
              />
              {!areOrdersInCompleteState && (
                <Alert status="warning">
                  <Text as="p">
                    Please fill in all fields or move partially filled orders to schedule to enable 'Release' button
                  </Text>
                </Alert>
              )}
              <HStack gap="8px" justifyContent="flex-end" margin="14px 16px 10px">
                {this.state.isLoading && showPreviewBtn ? (
                  <Loading />
                ) : (
                  <ChakraButton
                    variant="outline"
                    colorScheme="actionSecondary"
                    onClick={() => {
                      this.previewPdf(orders);
                    }}
                    disabled={!areOrdersInCompleteState}
                    height="32px"
                    width="196px"
                    fontWeight="black"
                  >
                    <MdOutlineKeyboardArrowDown />
                    Preview Order as PDF
                  </ChakraButton>
                )}
                <ChakraButton
                  colorScheme="actionSecondary"
                  onClick={() => {
                    this.setState({
                      isEmailModalOpen: true,
                      emailModalData: {
                        grinder,
                        transporter,
                        coldstore,
                        orders,
                      },
                      isEmailClicked: true,
                    });
                  }}
                  disabled={!areOrdersInCompleteState}
                  height="32px"
                  width="119px"
                  fontWeight="black"
                >
                  Release
                </ChakraButton>
                {this.state.isEmailModalOpen && (
                  <EmailModal
                    data={this.state.emailModalData}
                    visible={this.state.isEmailModalOpen}
                    closeModal={() => {
                      this.props.clearColdStoreEmailModal();
                      this.setState({ isEmailModalOpen: false });
                    }}
                    onSendEmails={this.props.onSendEmails}
                    coldstoreSendEmailError={this.props.coldstoreSendEmailError}
                    notifying_action_groups={this.props.notifying_action_groups}
                    user={this.props.user}
                  />
                )}
              </HStack>
            </Box>
          );
        })}
      </Box>
    );
  };
}

export default GrinderRelease;
