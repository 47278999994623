import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Box, Flex, Spacer, VStack, Text } from '@chakra-ui/react';

const HeadingWithButton = ({
  text = '',
  topText = null,
  bottomText = null,
  size = 'lg',
  buttonSubText = '',
  button = null,
  ...args
}) => {
  return (
    <Box mb="8">
      {topText}
      <Flex>
        <Box>
          <Heading size={size}>{text}</Heading>
        </Box>
        <Spacer />
        {button && (
          <Box>
            <VStack gap={2}>
              <Box>{button}</Box>
              <Box>
                <Text fontSize="md">{buttonSubText}</Text>
              </Box>
            </VStack>
          </Box>
        )}
      </Flex>
      {bottomText}
    </Box>
  );
};

HeadingWithButton.propTypes = {
  text: PropTypes.string,
  topText: PropTypes.instanceOf(Object),
  bottomText: PropTypes.instanceOf(Object),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  button: PropTypes.instanceOf(Object),
  buttonSubText: PropTypes.string,
};

export default HeadingWithButton;
