import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Heading, Image } from '@chakra-ui/react';
// Local Deps
import Button from '../../core/Button';
import CircleIcon from '../../core/CircleIcon';
import LoadingGraphic from '../../../assets/color-ring-loading.svg';
import {
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from '@/components/ui/dialog';
import { MdCheckCircle } from 'react-icons/md';

const propTypes = {
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onBackToSummaryClick: PropTypes.func,
  onCloseModal: PropTypes.func,
  onNewSearchClick: PropTypes.func,
  wasSuccessful: PropTypes.bool,
};

const BulkUpdaterModal = props => {
  const { isOpen, onCloseModal, isSubmitting, wasSuccessful, error, onBackToSummaryClick, onNewSearchClick } = props;

  return (
    <DialogRoot size="md" open={isOpen} onClose={onCloseModal}>
      <DialogContent minHeight="200px">
        <DialogHeader>
          <Heading size="md">Bulk Update</Heading>
        </DialogHeader>
        <DialogCloseTrigger asChild onClick={onCloseModal} />
        <DialogBody>
          {/* <!-- CARD --> */}
          <Box>{wasSuccessful ? 'The operation was successful.' : ''}</Box>
          <Flex mb="35px" textAlign="center" justifyContent="center">
            {/* <!-- LOADING GIF --> */}
            {isSubmitting && (
              <Box textAlign="center">
                <Image src={LoadingGraphic} />
                Submitting changes...
              </Box>
            )}
            {/* <!-- SUCCESS --> */}
            {wasSuccessful && !error && <MdCheckCircle size="80px" color="green.600" />}
            {/* <!-- ERROR --> */}
            {error && !wasSuccessful && (
              <Box>
                <CircleIcon icon="close" color="error" />
                <Box mt="10px">Operation failed due to an unexpected error.</Box>
              </Box>
            )}
          </Flex>
        </DialogBody>
        <DialogFooter>
          {wasSuccessful && (
            <>
              {/* TODO -- In the future we will add a button that allows the user to go back
             to the previous search.  We aren't persisting the search form's state at this time, though,
             so only new searches are offered here. */}
              <Button colorScheme="actionPrimary" onClick={onNewSearchClick}>
                New Search
              </Button>
            </>
          )}
          {error && (
            <>
              <Button colorScheme="actionPrimary" onClick={onBackToSummaryClick}>
                Back to Summary
              </Button>
            </>
          )}
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

BulkUpdaterModal.propTypes = propTypes;

export default BulkUpdaterModal;
