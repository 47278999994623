import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Text } from '@chakra-ui/react';
import { Field as ChakraField } from '../ui/field';
import { Switch } from '@/components/ui/switch';

function SwitchComponent(props) {
  const { name, onChange, defaultIsChecked, label, ...rest } = props;
  const { colorScheme } = rest;

  return (
    <ChakraField display="flex" justifyContent="center">
      <Field name={name} id={name} type="boolean">
        {({ field: { value }, form: { setFieldValue } }) => (
          <>
            <Switch
              {...{
                id: name,
                isChecked: value,
                onChange: () => {
                  setFieldValue(name, !value);
                  if (onChange) {
                    onChange(name, !value, setFieldValue);
                  }
                },
                defaultIsChecked,
                colorScheme: colorScheme || 'primary',
                marginBottom: '0px',
              }}
            />
            <Text mb="0" pl="12px">
              {typeof label === 'function' ? label(value) : label}
            </Text>
          </>
        )}
      </Field>
    </ChakraField>
  );
}

SwitchComponent.propTypes = {
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultIsChecked: PropTypes.bool,
};

export default SwitchComponent;
