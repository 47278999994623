import React from 'react';
import PropTypes from 'prop-types';
import { Text, HStack } from '@chakra-ui/react';
import {
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from '@/components/ui/dialog';

export const EmailModalOrderDetails = ({ grinderName, coldstoreLocationName, transporterName, ...rest }) => {
  return (
    <HStack gap="25px" paddingLeft="65px" paddingTop="0px" paddingBottom="9px" borderBottomWidth="1px" {...rest}>
      <HStack gap="5px">
        <Text as="p" fontWeight="bold">
          Grinder:
        </Text>
        <Text as="p">{grinderName}</Text>
      </HStack>
      <HStack gap="5px">
        <Text as="p" fontWeight="bold">
          Coldstore:
        </Text>
        <Text as="p">{coldstoreLocationName}</Text>
      </HStack>
      <HStack gap="5px">
        <Text as="p" fontWeight="bold">
          Trucking Company:
        </Text>
        <Text as="p">{transporterName}</Text>
      </HStack>
    </HStack>
  );
};

EmailModalOrderDetails.propTypes = {
  grinderName: PropTypes.string.isRequired,
  coldstoreLocationName: PropTypes.string.isRequired,
  transporterName: PropTypes.string.isRequired,
};

export const EmailModalBaseComponent = ({ visible, onExit, headerTitle, modalBodyContent, modalFooterContent }) => {
  return (
    <DialogRoot open={visible} onClose={onExit} size="full">
      <DialogContent maxWidth="895px">
        <DialogHeader borderBottom="1px" borderColor="gray.200" marginBottom="12px">
          <Text as="p" fontSize="23px" fontWeight="semibold">
            {headerTitle}
          </Text>
          <DialogCloseTrigger asChild onClick={onExit} marginTop="8px" />
        </DialogHeader>
        <DialogBody padding="0px">{modalBodyContent}</DialogBody>
        <DialogFooter borderTopWidth="1px" marginTop="21px">
          {modalFooterContent}
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

EmailModalBaseComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  modalBodyContent: PropTypes.elementType.isRequired,
  modalFooterContent: PropTypes.elementType.isRequired,
};
