import React, { useEffect } from 'react';
import { useCounter } from '@chakra-ui/counter';
import { Box, Text } from '@chakra-ui/react';
import { ErrorMessage, Field as FormikField, useField } from 'formik';
import Button from '../Button';
import { Field } from '@/components/ui/field';
import { MdAdd, MdOutlineRemove } from 'react-icons/md';

function Counter(props) {
  const { indices, name, label, type, value, min, max, step, ml, ...rest } = props;
  const fieldName = typeof name === 'function' ? name(indices) : name;

  const counter = useCounter({
    max: max || undefined,
    min: min || 1,
    step: 1,
  });

  const [helpers] = useField(fieldName);
  const { setValue } = helpers;

  useEffect(() => {
    setValue(counter.value ? Number(counter.value) : 0);
  }, [counter.value]);

  // Start at 1, not 0
  if (!counter.value) {
    counter.increment();
  }

  return (
    <Box borderWidth="1px" borderRadius="0.25rem" borderColor="gray.400" backgroundColor="white" ml="15px">
      <Field display="flex" flexDir="row">
        <Button type="borderless" onClick={() => counter.decrement()} pt="4px">
          <MdOutlineRemove size="20" color="primary" />
        </Button>
        <FormikField
          type={type}
          name={fieldName}
          value={counter.value}
          className="form-control"
          style={{
            borderColor: 'transparent',
            minWidth: 35,
            textAlign: 'center',
            padding: 0,
          }}
          {...rest}
        />
        <Button type="borderless" onClick={() => counter.increment()}>
          <MdAdd size="20" color="primary" />
        </Button>
      </Field>
      <ErrorMessage name={fieldName}>{msg => <Text color="error">{msg}</Text>}</ErrorMessage>
    </Box>
  );
}

Counter.defaultProps = {
  type: 'number',
  min: 1,
  step: 1,
};

export default Counter;
