import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function SimpleText(field) {
  const { label } = field;

  return (
    <Box>
      <Box display="flex" flexDir="column">
        <Text mb="7px">{label}</Text>
        <Box pl="12px" mb="3px">
          <Text fontSize="md" fontWeight="bold">
            {label}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default SimpleText;
