import { Popconfirm } from 'antd';
import differenceBy from 'lodash/differenceBy';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import kebabCase from 'lodash/kebabCase';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { FaAngleDown } from 'react-icons/fa';
import { Tooltip } from '@/components/ui/tooltip';

// Local Deps
import {
  deleteExceptionAssignment,
  fetchExceptionAssignments,
  patchExceptionAssignment,
  postExceptionAssignment,
} from '../../actions/actions_exception_assignments';
import { fetchGrinders } from '../../actions/actions_grinders';
import { fetchUsers } from '../../actions/actions_users';
import SelectField from '../basic/SelectField';
import './DailyTasksAdmin.scss';
import { Badge, Box, Button, Flex, HStack, IconButton, Table, Text, VStack, Heading, Group } from '@chakra-ui/react';
import Loading from '../basic/Loading';
import RightAngleIcon from '../core/RightAngleIcon';
import { MdCheck, MdDelete, MdEdit } from 'react-icons/md';
import axios from 'axios';
import Constants from '../../Constants';
import { DialogRoot, DialogContent, DialogHeader, DialogBody, DialogFooter } from '@/components/ui/dialog';
import { Tag } from '@/components/ui/tag';

const DailyTaskModalHeader = ({ children, ...rest }) => (
  <DialogHeader fontSize="18px" {...rest}>
    {children}
  </DialogHeader>
);

DailyTaskModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

const ModalFooterButtons = ({ onCancel, isDisabled, onConfirm, confirmButtonText }) => {
  return (
    <Group height="40px" fontSize="16px" gap="17px">
      <Button height="100%" fontSize="inherit" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        height="100%"
        fontSize="inherit"
        variant="solid"
        colorScheme="actionPrimary"
        disabled={isDisabled}
        onClick={onConfirm}
      >
        {confirmButtonText || 'Apply Changes'}
      </Button>
    </Group>
  );
};

ModalFooterButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string,
};

class UserAssignment extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onDoneClick: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    value: PropTypes.any.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
  };

  static arrowRenderer() {
    return (
      <span>
        <FaAngleDown />
      </span>
    );
  }

  render() {
    const { user, options, value, onChange, onEditClick, onDeleteClick, onDoneClick, isInEditMode } = this.props;
    const name = `${user.first_name} ${user.last_name}`;
    return (
      <div className="user-assignment">
        <div className="user-assignment__label">
          <div className="user-assignment__name">{name}</div>
          <div className="user-assignment__actions">
            {isInEditMode ? (
              <Button className="user-assignment__button" color="link" onClick={() => onDoneClick(user.id)}>
                <MdCheck />
              </Button>
            ) : (
              <span>
                <Button className="user-assignment__button" color="link" onClick={() => onEditClick(user.id)}>
                  <MdEdit />
                </Button>
                <Popconfirm
                  placement="top"
                  title={`Are you sure you want to clear grinder assignments for ${user.first_name}?`}
                  onConfirm={() => onDeleteClick(user)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="user-assignment__button" color="link">
                    <MdDelete />
                  </Button>
                </Popconfirm>
              </span>
            )}
          </div>
        </div>
        <div className="user-assignment__select-container">
          {isInEditMode ? (
            <SelectField
              className="user-assignment__select"
              closeMenuOnSelect={false}
              removeSelected
              name={name}
              isMulti
              placeholder="Select Grinder..."
              defaultValue={value}
              arrowRenderer={UserAssignment.arrowRenderer}
              onChange={selectedOption => onChange(selectedOption, user.id)}
              options={options || []}
            />
          ) : (
            <div className="user-assignment__grinders">
              {value.map(val => (
                <div
                  key={val.value}
                  className={`user-assignment__grinder user-assignment__grinder--${kebabCase(val.country)}`}
                >
                  {val.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

class DailyTasksAdmin extends React.Component {
  static propTypes = {
    users: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.arrayOf(PropTypes.object)]),
    grinders: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.arrayOf(PropTypes.object)]),
    exception_assignments: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.arrayOf(PropTypes.object)]),
    token: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static createOption(item) {
    const country = get(item, 'destination_country');
    return {
      label: item.name || `${item.first_name} ${item.last_name}`,
      value: item.id ? item.id : item.uid,
      ...(country ? { country } : {}),
    };
  }

  state = {
    isLoading: false,
    userOptions: null,
    grinderOptions: null,
    exceptionAssignments: null,
    taskTypeOptions: null,
    userAssignments: {},
    userBeingEdited: '',
    modalIsOpen: false,
    modalIsLoading: false,
    availableUsers: [],
    availableGrinders: [],
    isAddModalOpen: false,
    isEditModalOpen: false,
    isDeleteModalOpen: false,
    userBeingEditedName: '',
    userToBeDeleted: null,
    addAssignmentValues: {
      selectedUser: {},
      selectedGrinders: [],
    },
    editAssignmentValues: {
      selectedUser: {},
      selectedGrinders: [],
    },
    selectedTaskType: '',
  };

  async componentDidMount() {
    const { token, dispatch } = this.props;
    dispatch(fetchUsers(token));
    dispatch(fetchGrinders(token));

    const exceptionTypes = await axios.get(`${Constants.URL}exception_types`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Token ${token}` },
    });
    // Exception Type Options:
    const taskTypeOptions = get(exceptionTypes, 'data', []).map(taskTypeOption => ({
      value: taskTypeOption.name,
      label: taskTypeOption.label,
    }));

    return this.setState({
      taskTypeOptions,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { users, grinders, exception_assignments, dispatch, token } = this.props;
    const { selectedTaskType } = this.state;

    if (this.state.userOptions === null) {
      const userOptions = users.map(DailyTasksAdmin.createOption);
      return this.setState(
        {
          userOptions: userOptions || [],
        },
        () => {
          dispatch(fetchGrinders(token));
        }
      );
    }

    if (grinders && this.state.grinderOptions === null) {
      const grinderOptions = grinders.map(DailyTasksAdmin.createOption);
      return this.setState({
        grinderOptions: grinderOptions || [],
      });
    }
    if (prevProps.exception_assignments !== this.props.exception_assignments) {
      const exceptionAssignments = exception_assignments.map(ea => {
        return {
          ...ea,
          option: DailyTasksAdmin.createOption(ea.grinder),
        };
      });

      const assignedGrinders = exceptionAssignments.map(ea => ea.grinder.uid);
      const assignedUsers = exceptionAssignments.map(ea => ea.user.id);

      // Available grinders are ones that aren't already assigned
      const availableGrinders = grinders
        .filter(grinder => !assignedGrinders.includes(grinder.uid))
        .map(DailyTasksAdmin.createOption);

      const sortedGrinders = orderBy(availableGrinders, [grinder => grinder.label.toLowerCase()], ['asc']);

      // Available users are ones that aren't already assigned
      const availableUsers = users.filter(usr => !assignedUsers.includes(usr.id)).map(DailyTasksAdmin.createOption);

      const sortedUsers = orderBy(availableUsers, [user => user.label.toLowerCase()], ['asc']);
      // Group assignments by user
      const groupedAssignmentsByUser = groupBy(exceptionAssignments, 'user.id');

      // Convert assignments to options
      const userAssignments = Object.keys(groupedAssignmentsByUser).reduce((agg, userId) => {
        return {
          ...agg,
          [userId]: agg[userId].map(item => item.option),
        };
      }, groupedAssignmentsByUser);

      return this.setState({
        exceptionAssignments,
        userAssignments,
        availableGrinders: sortedGrinders,
        availableUsers: sortedUsers,
        isLoading: false,
      });
    }

    if (prevState.selectedTaskType !== selectedTaskType) {
      if (selectedTaskType) {
        return this.setState(
          {
            isLoading: true,
          },
          () => {
            dispatch(fetchExceptionAssignments(token, selectedTaskType?.value));
          }
        );
      }
      this.setState({ exceptionAssignments: null, userAssignments: {} });
    }
  }

  handleAssignmentDelete = (currentSelections, userId, assignments) => {
    const { userAssignments, availableGrinders } = this.state;
    const { dispatch, token } = this.props;

    const updatedUserAssignments = {
      ...userAssignments,
      [userId]: currentSelections,
    };

    // Identify grinders that were deleted
    const deletedGrinders = differenceBy(assignments, currentSelections, 'value');

    const updatedAvailableGrinders = [...availableGrinders, ...deletedGrinders];

    // Update state before making requests for snappy UI response
    this.setState(
      {
        availableGrinders: updatedAvailableGrinders,
        userAssignments: updatedUserAssignments,
      },
      () => {
        deletedGrinders.map(grinder => dispatch(deleteExceptionAssignment(grinder.value, token)));
      }
    );
  };

  handleAssignmentAdd = (currentSelections, userId, assignments) => {
    const { userAssignments, availableGrinders, selectedTaskType } = this.state;
    const { dispatch, token } = this.props;

    // Update user assignments by userId
    const updatedUserAssignments = {
      ...userAssignments,
      [userId]: currentSelections,
    };

    // Update available grinders list
    const addedGrindersList = differenceBy(currentSelections, assignments, 'value');

    const addedGrinder = get(addedGrindersList, '[0]', {});

    const updatedAvailableGrinders = availableGrinders.filter(grinder => grinder.value !== addedGrinder.value);

    this.setState(
      {
        availableGrinders: updatedAvailableGrinders,
        userAssignments: updatedUserAssignments,
      },
      () => {
        dispatch(
          postExceptionAssignment(
            {
              grinder_uid: addedGrinder.value,
              user_id: userId,
              exception_type_name: selectedTaskType?.value,
            },
            token
          )
        );
      }
    );
  };

  onChange = (currentSelections, userId) => {
    const { userAssignments } = this.state;
    const assignments = get(userAssignments, userId, []).map(ua => ua.option || ua);

    // Handle deleted user-grinder assignment:
    if (assignments.length > currentSelections.length) {
      this.handleAssignmentDelete(currentSelections, userId, assignments);
    }

    if (assignments.length < currentSelections.length) {
      this.handleAssignmentAdd(currentSelections, userId, assignments);
    }
  };

  handleEditClick = user => {
    this.setState(prevState => ({
      ...prevState,
      editAssignmentValues: {
        ...prevState.editAssignmentValues,
        selectedGrinders: get(prevState.userAssignments, `${user.id}`, []),
      },
      userBeingEdited: user.id.toString(),
      userBeingEditedName: `${user.first_name} ${user.last_name}`,
      isEditModalOpen: true,
    }));
  };

  handleDeleteClick = user => {
    this.setState({
      userToBeDeleted: user,
      isDeleteModalOpen: true,
    });
  };

  handleDelete = async () => {
    const { userAssignments, availableUsers, availableGrinders, grinderOptions, userToBeDeleted: user } = this.state;
    const { dispatch, token } = this.props;
    // Create list of grinder ids that will be deleted
    const grinderIds = userAssignments[user.id].map(grinder => grinder.value);

    const updatedAvailableGrinders = [
      ...availableGrinders,
      ...grinderOptions.filter(option => grinderIds.includes(option.value)),
    ];
    const updatedAvailableUsers = [...availableUsers, DailyTasksAdmin.createOption(user)];

    this.setState(
      {
        availableGrinders: updatedAvailableGrinders,
        availableUsers: updatedAvailableUsers,
        isDeleteModalOpen: false,
      },
      () => {
        dispatch(deleteExceptionAssignment(user.id, grinderIds, token));
      }
    );
  };

  handleDoneClick = () => {
    this.setState({
      userBeingEdited: '',
    });
  };

  handleAddUserClick = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  handleAddAssignmentModalSubmit = async () => {
    const { addAssignmentValues, selectedTaskType } = this.state;
    const { selectedUser, selectedGrinders } = addAssignmentValues;
    const { dispatch, token } = this.props;

    // Update state before updating DB for snappy UI response
    this.setState(
      {
        modalIsLoading: false,
        modalIsOpen: false,
        isAddModalOpen: false,
        addAssignmentValues: {
          selectedUser: {},
          selectedGrinders: [],
        },
      },
      () => {
        selectedGrinders.map(grinder =>
          dispatch(
            postExceptionAssignment(
              {
                grinder_uid: grinder.value,
                user_id: selectedUser.value,
                exception_type_name: selectedTaskType?.value,
              },
              token
            )
          ).then(v => {
            dispatch(fetchExceptionAssignments(token, this.state.selectedTaskType.value));
          })
        );
      }
    );
  };

  handleEditAssignmentModalSubmit = async () => {
    const { editAssignmentValues, userBeingEdited: selectedUser, selectedTaskType } = this.state;
    const { selectedGrinders } = editAssignmentValues;
    const { dispatch, token } = this.props;

    // Update state before updating DB for snappy UI response
    this.setState(
      {
        modalIsLoading: false,
        modalIsOpen: false,
        isEditModalOpen: false,
        userBeingEdited: {},
        editAssignmentValues: {
          selectedGrinders: [],
        },
      },
      () => {
        dispatch(
          patchExceptionAssignment(
            {
              grinders_uid: selectedGrinders.map(grinder => grinder.value),
              exception_type_name: selectedTaskType?.value,
            },
            selectedUser,
            token
          )
        ).then(v => {
          dispatch(fetchExceptionAssignments(token, this.state.selectedTaskType.value));
        });
      }
    );
  };

  handleModalCancel = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  handleTaskTypeChange = value => {
    if (value !== null) {
      this.setState({ selectedTaskType: value });
    } else {
      this.setState({
        selectedTaskType: value,
        isLoading: false,
        userOptions: null,
        grinderOptions: null,
        exceptionAssignments: null,
        userAssignments: {},
        userBeingEdited: '',
        modalIsOpen: false,
        modalIsLoading: false,
        availableUsers: [],
        availableGrinders: [],
        isAddModalOpen: false,
        isEditModalOpen: false,
        isDeleteModalOpen: false,
        userBeingEditedName: '',
        userToBeDeleted: null,
        addAssignmentValues: {
          selectedUser: {},
          selectedGrinders: [],
        },
        editAssignmentValues: {
          selectedUser: {},
          selectedGrinders: [],
        },
        selectedTaskType: '',
      });
    }
  };

  render() {
    const { users } = this.props;

    const {
      isLoading,
      userAssignments,
      editAssignmentValues,
      userBeingEdited,
      availableGrinders,
      availableUsers,
      isAddModalOpen,
      isEditModalOpen,
      userBeingEditedName,
      isDeleteModalOpen,
      addAssignmentValues,
      taskTypeOptions,
    } = this.state;
    const userIds = Object.keys(userAssignments) || [];

    const labelProps = { fontSize: '16px', width: '111px' };

    return (
      <VStack align="stretch" gap="28px">
        <Flex>
          <Heading fontSize="18px">Admin</Heading>
          <HStack ml="auto" gap="13px">
            <Link to="/daily-tasks" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button variant="outline" colorScheme="actionPrimary" size="lg">
                <Text as="p" fontSize="16px">
                  Dashboard
                </Text>
              </Button>
            </Link>
            <Link to="/daily-tasks?view=list" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button variant="solid" colorScheme="actionPrimary" size="lg" fontWeight="600" fontSize="16px">
                List View
                <i className="cfc-icon icon-ui-bullets" />
              </Button>
            </Link>
            <Button
              variant="solid"
              colorScheme="actionPrimary"
              size="lg"
              fontWeight="600"
              fontSize="16px"
              disabled={!this.state.selectedTaskType}
              onClick={() => this.setState({ isAddModalOpen: true })}
            >
              Add User
            </Button>
          </HStack>
        </Flex>
        <VStack
          align="stretch"
          paddingTop="13px"
          paddingBottom="52px"
          paddingX="27px"
          boxShadow="md"
          borderRadius="15px"
          backgroundColor="card.default"
          gap="30px"
        >
          <Flex alignItems="center" width="100%">
            <RightAngleIcon />
            <Heading fontSize="15px" marginLeft="10px">
              Daily Task Assignments
            </Heading>
          </Flex>
          <Box>
            <SelectField
              defaultValue="purchase_unshipped"
              options={taskTypeOptions}
              isClearable
              onChange={this.handleTaskTypeChange}
              label="Select Task"
              containerWidth="264px"
              labelProps={labelProps}
              asHorizontalStack
              containerStackProps={{
                align: 'center',
              }}
            />
          </Box>

          {isLoading ? (
            <Box minW="100%">
              <Loading />
            </Box>
          ) : (
            userIds.length > 0 && (
              <Box bgColor="white">
                <Table.Root variant="simple">
                  <Table.Header backgroundColor="card.default">
                    <Table.Row>
                      <Table.ColumnHeader fontSize="12px">NAME</Table.ColumnHeader>
                      <Table.ColumnHeader fontSize="12px">GRINDER</Table.ColumnHeader>
                      <Table.ColumnHeader fontSize="12px" textAlign="center">
                        ACTIONS
                      </Table.ColumnHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {userIds.map((userId, idx) => {
                      const assignments = get(userAssignments, `${userId}`, []);
                      const user = users.find(usr => usr.id.toString() === userId);
                      return (
                        <Table.Row key={`user-assignment-${idx}`} height="72px">
                          <Table.Cell>
                            <HStack gap="12px">
                              <Badge
                                variant="solid"
                                width="40px"
                                height="40px"
                                borderRadius="40px"
                                colorScheme="actionSecondary"
                              >
                                <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
                                  <Text as="p" fontSize="14px" fontWeight="medium">
                                    {user.first_name.substr(0, 1) + user.last_name.substr(0, 1)}
                                  </Text>
                                </Flex>
                              </Badge>
                              <Text as="p" fontSize="14px" fontWeight="medium">
                                {`${user.first_name} ${user.last_name}`}
                              </Text>
                            </HStack>
                          </Table.Cell>
                          <Table.Cell>
                            <HStack gap="17px">
                              {assignments.map(assignment => (
                                <Tag
                                  key={assignment.label}
                                  variant="solid"
                                  height="25px"
                                  paddingY="2px"
                                  paddingX="8px"
                                  borderRadius="6px"
                                  colorScheme="actionSecondary"
                                >
                                  <Text as="p" fontSize="14px" fontWeight="medium" lineHeight="20px">
                                    {assignment.label}
                                  </Text>
                                </Tag>
                              ))}
                            </HStack>
                          </Table.Cell>
                          <Table.Cell width="118px">
                            <HStack gap="12px">
                              <Tooltip hasArrow content="Edit Assignment">
                                <IconButton
                                  aria-label="Edit"
                                  variant="ghost"
                                  onClick={() => this.handleEditClick(user)}
                                >
                                  <MdEdit />
                                </IconButton>
                              </Tooltip>
                              <Tooltip hasArrow content="Delete Assignment">
                                <IconButton
                                  aria-label="Delete"
                                  variant="ghost"
                                  onClick={() => this.handleDeleteClick(user)}
                                >
                                  <MdDelete />
                                </IconButton>
                              </Tooltip>
                            </HStack>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Root>
              </Box>
            )
          )}

          <DialogRoot open={isAddModalOpen} size="md" centered>
            <DialogContent>
              <DailyTaskModalHeader>Assign Grinders to New User</DailyTaskModalHeader>
              <DialogBody>
                <VStack align="stretch" gap="10px" marginY="10px">
                  <Box>
                    <SelectField
                      options={availableUsers}
                      onChange={value => {
                        this.setState(prevState => ({
                          ...prevState,
                          addAssignmentValues: {
                            ...prevState.addAssignmentValues,
                            selectedUser: value,
                          },
                        }));
                      }}
                      // {...sf}
                      label="Select User"
                      containerWidth="100%"
                      labelProps={labelProps}
                      asHorizontalStack
                      containerStackProps={{
                        align: 'center',
                      }}
                      value={addAssignmentValues.selectedUser}
                    />
                  </Box>
                  <Box>
                    <SelectField
                      options={availableGrinders}
                      onChange={value => {
                        this.setState(prevState => ({
                          ...prevState,
                          addAssignmentValues: {
                            ...prevState.addAssignmentValues,
                            selectedGrinders: value,
                          },
                        }));
                      }}
                      label="Select Grinder"
                      containerWidth="100%"
                      labelProps={labelProps}
                      asHorizontalStack
                      containerStackProps={{
                        align: 'center',
                      }}
                      isMulti
                      value={addAssignmentValues.selectedGrinders}
                    />
                  </Box>
                </VStack>
              </DialogBody>
              <DialogFooter>
                <ModalFooterButtons
                  onCancel={() =>
                    this.setState({
                      isAddModalOpen: false,
                      addAssignmentValues: {
                        selectedUser: {},
                        selectedGrinders: [],
                      },
                    })
                  }
                  onConfirm={this.handleAddAssignmentModalSubmit}
                  disabled={
                    addAssignmentValues.selectedUser.length === 0 || addAssignmentValues.selectedGrinders.length === 0
                  }
                />
              </DialogFooter>
            </DialogContent>
          </DialogRoot>
          <DialogRoot open={isEditModalOpen} size="md" centered>
            <DialogContent>
              <DailyTaskModalHeader>Edit Assignment</DailyTaskModalHeader>
              <DialogBody>
                <VStack align="stretch" gap="10px" marginY="10px">
                  <Box>
                    <SelectField
                      placeholder={userBeingEditedName}
                      // {...sf}
                      label="Select User"
                      containerWidth="100%"
                      labelProps={labelProps}
                      asHorizontalStack
                      containerStackProps={{
                        align: 'center',
                      }}
                      value={userBeingEdited}
                      disabled
                    />
                  </Box>
                  <Box>
                    <SelectField
                      options={availableGrinders}
                      onChange={value => {
                        this.setState(prevState => ({
                          ...prevState,
                          editAssignmentValues: {
                            ...prevState.editAssignmentValues,
                            selectedGrinders: value,
                          },
                        }));
                      }}
                      label="Select Grinder"
                      containerWidth="100%"
                      labelProps={labelProps}
                      asHorizontalStack
                      containerStackProps={{
                        align: 'center',
                      }}
                      isMulti
                      value={editAssignmentValues.selectedGrinders}
                    />
                  </Box>
                </VStack>
              </DialogBody>
              <DialogFooter>
                <ModalFooterButtons
                  onCancel={() =>
                    this.setState({
                      isEditModalOpen: false,
                    })
                  }
                  disabled={!editAssignmentValues.selectedGrinders.length}
                  onConfirm={this.handleEditAssignmentModalSubmit}
                />
              </DialogFooter>
            </DialogContent>
          </DialogRoot>
          <DialogRoot open={isDeleteModalOpen} size="md" centered>
            <DialogContent>
              <DailyTaskModalHeader>Delete Assignment</DailyTaskModalHeader>
              <DialogBody>
                <VStack align="stretch" gap="10px" marginY="10px">
                  <Box>
                    <Text as="p" fontSize="16px" fontWeight="medium" textAlign="center">
                      Do you want to remove this assignment?
                    </Text>
                  </Box>
                </VStack>
              </DialogBody>
              <DialogFooter>
                {/* <Button mr="17px" onClick={() => this.setState({ isDeleteModalOpen: false })}>
                  Cancel
                </Button>
                <Button variant="solid" colorScheme="nav" onClick={this.handleDelete}>
                  Confirm
                </Button> */}

                <ModalFooterButtons
                  onCancel={() =>
                    this.setState({
                      isDeleteModalOpen: false,
                    })
                  }
                  onConfirm={this.handleDelete}
                  confirmButtonText="Confirm"
                />
              </DialogFooter>
            </DialogContent>
          </DialogRoot>
        </VStack>
      </VStack>
    );
  }
}

export default DailyTasksAdmin;
