import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ErrorMessage, Field } from 'formik';
import { Field as ChakraField } from '@/components/ui/field';

function FormInput(field) {
  const { indices, name, label, type, value, onChange, onBlur, ...rest } = field;
  const fieldName = typeof name === 'function' ? name(indices) : name;

  return (
    <Box w="full">
      <ChakraField display="flex" flexDir="column" label={label}>
        <Field>
          {({ form: { setFieldValue } }) => (
            <Box w="full">
              <input
                {...{
                  type,
                  name: fieldName,
                  value: value || '',
                  style: {
                    borderColor: '#ccc',
                    paddingLeft: rest.px || rest.pl || rest.paddingLeft || '11px',
                    paddingRight: rest.px || rest.pr || rest.paddingRight || '11px',
                    paddingTop: rest.py || rest.pt || rest.paddingTop || '6px',
                    paddingBottom: rest.py || rest.pb || rest.paddingBottom || '6px',
                    lineHeight: rest.lineHeight || '21px',
                  },
                  className: 'form-control',
                  onChange: e => {
                    setFieldValue(fieldName, e.target.value);
                    if (onChange) {
                      onChange(fieldName, e.target.value, setFieldValue);
                    }
                  },
                  ...(onBlur && { onBlur: e => onBlur(fieldName, e.target.value, setFieldValue) }),
                  ...rest,
                }}
              />
            </Box>
          )}
        </Field>
      </ChakraField>
      <ErrorMessage name={fieldName}>{msg => <Text color="error">{msg}</Text>}</ErrorMessage>
    </Box>
  );
}

export default FormInput;
