import React from 'react';
import PropTypes from 'prop-types';
import { Field } from '@/components/ui/field';

const CustomFormControl = ({ children, ...rest }) => {
  return (
    <Field width="100%" position="relative" {...rest}>
      {children}
    </Field>
  );
};

CustomFormControl.propTypes = {
  children: PropTypes.node.isRequired,
  changeableKey: PropTypes.string,
};

const areEqual = (prevProps, nextProps) => {
  if (!nextProps?.changeableKey) {
    return false;
  }
  if (prevProps.changeableKey === nextProps.changeableKey) {
    return true;
  }
  return false;
};

export default React.memo(CustomFormControl, areEqual);
