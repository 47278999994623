import React, { useEffect, useState, useReducer, useRef } from 'react';
import { MdAdd } from 'react-icons/md';
import { Box, Text, VStack, Flex, Separator, Badge, Spinner, Table } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import SelectField from '../basic/SelectField';
import { UserSelectFieldOption, UserSelectFieldValueContainer } from './UserSelectFieldComponents';
import CategoryTag from './CategoryTag';
import ResolutionButton from './ResolutionButton';
import EditInternationalOrder from '../../containers/orders/EditInternationalOrder';
import OrderTableModal from '../../containers/orders/OrderTableModal';
import { fetchNetsuitePurchaseOrder } from '../../actions/actions_orders';
import { checkIfChicagoForm } from '../lib/PurchaseOrderFunctions';
import { Tooltip } from '@/components/ui/tooltip';
import { PopoverRoot, PopoverContent, PopoverBody, PopoverTrigger } from '@/components/ui/popover';

// PurchaseOrderFunctions
const initialState = {
  isLoading: false,
  exceptionId: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'loading':
      return {
        isLoading: true,
        exceptionId: action.payload,
      };
    case 'done':
      return {
        isLoading: false,
        exceptionId: null,
      };
    default:
      throw new Error();
  }
}

const TableBodyContent = ({ allPurchaseOrders, onResolveClick, userOptions, onAssign, token }) => {
  const initRef = useRef();
  const previousOrder = useRef();
  const [showEditForm, setShowEditForm] = useState(false);
  const [isChicagoForm, setIsChicagoForm] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [userAssignmentState, dispatchAssignment] = useReducer(reducer, initialState);
  const [userResolveState, dispatchResolve] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentOrder) {
      dispatch(fetchNetsuitePurchaseOrder(currentOrder.id, token)).then(() => {
        const formIsChicago = checkIfChicagoForm({
          purchasingOffice: currentOrder.purchasing_office,
          invoicingOffice: currentOrder.invoicing_office,
        });
        previousOrder.current = currentOrder;
        setIsChicagoForm(formIsChicago);
        setShowEditForm(!showEditForm);
      });
    }
  }, [currentOrder]);

  const handleClosePOEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  const handleOptionSelect = (exceptionId, selectedOption, assign = true) => {
    dispatchAssignment({ type: 'loading', payload: exceptionId });
    if (assign) {
      // Add or update user assignment
      onAssign(exceptionId, { assignee: selectedOption.value.toString() }).finally(() => {
        dispatchAssignment({ type: 'done' });
      });
    } else {
      // Remove user assignment
      onAssign(exceptionId, { assignee: null }).finally(() => {
        dispatchAssignment({ type: 'done' });
      });
    }
  };

  return (
    <>
      {showEditForm &&
        currentOrder &&
        (isChicagoForm ? (
          <OrderTableModal selectCurrentModalDetail={handleClosePOEditForm} />
        ) : (
          <EditInternationalOrder onClose={handleClosePOEditForm} />
        ))}

      {!isEmpty(allPurchaseOrders.purchase_orders) ? (
        allPurchaseOrders.purchase_orders.map(data => {
          const { purchase_order: purchaseOrder } = data;
          const { exceptions } = purchaseOrder;

          return (
            <Table.Row key={purchaseOrder.id}>
              <Table.Cell fontWeight="medium">
                <Text
                  data-po-number={get(purchaseOrder, 'internal_po_number')}
                  onClick={() => setCurrentOrder({ ...purchaseOrder })}
                  _hover={{ cursor: 'pointer' }}
                  fontWeight="600"
                >
                  {get(purchaseOrder, 'grinder_po_number')}
                </Text>
              </Table.Cell>
              <Table.Cell>
                <Text>{get(purchaseOrder, 'internal_po_number')}</Text>
              </Table.Cell>
              <Table.Cell>
                <Text>{get(data, 'grinder_name')}</Text>
              </Table.Cell>
              <Table.Cell>
                <Text>{get(data, 'packer_name')}</Text>
              </Table.Cell>
              <Table.Cell paddingX={0}>
                <VStack align="stretch" separator={<Separator borderWidth="1.5px" style={{ margin: '10px 0' }} />}>
                  {exceptions.map(exception => {
                    return (
                      <Flex key={exception.id} alignItems="center" height="43px">
                        <CategoryTag
                          category={get(exception, 'exception_type.category')}
                          label={get(exception, 'exception_type.label')}
                        />
                      </Flex>
                    );
                  })}
                </VStack>
              </Table.Cell>
              <Table.Cell paddingX={0}>
                <VStack
                  align="stretch"
                  alignItems="center"
                  separator={<Separator borderWidth="1.5px" style={{ margin: '10px 0' }} />}
                >
                  {exceptions.map(exception => {
                    const assignedUser = exception.assignee
                      ? {
                          value: exception.assigned_user.id,
                          label: `${exception.assigned_user.first_name} ${exception.assigned_user.last_name}`,
                          selected: true,
                        }
                      : null;
                    const userOptionsUpdated = userOptions
                      .map(userOption => (userOption.value === assignedUser?.value ? assignedUser : userOption))
                      .reduce((acc, element) => {
                        if (element.selected) {
                          return [element, ...acc];
                        }
                        return [...acc, element];
                      }, []);
                    return (
                      <Flex key={exception.id} alignItems="center" color="gray.400" height="43px">
                        {userAssignmentState.isLoading && userAssignmentState.exceptionId === exception.id ? (
                          <Spinner cursor="pointer" />
                        ) : (
                          <PopoverRoot isLazy placement="left" initialFocusRef={initRef}>
                            {!isEmpty(get(exception, 'assigned_user')) ? (
                              <>
                                <PopoverTrigger>
                                  <Box tabIndex="0" role="button" aria-label="Some box">
                                    <Tooltip
                                      hasArrow
                                      content={`${get(exception, 'assigned_user.first_name')} ${get(
                                        exception,
                                        'assigned_user.last_name'
                                      )}`}
                                      bg="black"
                                      placement="top"
                                    >
                                      <Badge
                                        variant="solid"
                                        width="30px"
                                        height="30px"
                                        borderRadius="30px"
                                        colorScheme="actionSecondary"
                                      >
                                        <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
                                          <Text fontWeight="medium">
                                            {get(exception, 'assigned_user.first_name', '').trim().slice(0, 1)}
                                            {get(exception, 'assigned_user.last_name', '').trim().slice(0, 1)}
                                          </Text>
                                        </Flex>
                                      </Badge>
                                    </Tooltip>
                                  </Box>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverBody padding={0}>
                                    <SelectField
                                      placeholder="Search"
                                      options={userOptionsUpdated}
                                      optionStyles={{
                                        pointerEvents: 'none',
                                      }}
                                      onChange={selectedOption => {
                                        if (selectedOption.value === assignedUser.value) {
                                          // Unassign user
                                          handleOptionSelect(exception.id, selectedOption, false);
                                        } else {
                                          // Assign user
                                          handleOptionSelect(exception.id, selectedOption);
                                        }
                                      }}
                                      containerWidth="100%"
                                      asHorizontalStack
                                      containerStackProps={{
                                        align: 'center',
                                      }}
                                      controlStyles={{
                                        borderRadius: '0px',
                                      }}
                                      menuStyles={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        borderRadius: '0px',
                                      }}
                                      placeholderStyles={{ marginLeft: '20px' }}
                                      singleValueStyles={{ marginLeft: '20px' }}
                                      components={{
                                        DropdownIndicator: () => null,
                                        ValueContainer: UserSelectFieldValueContainer,
                                        Option: UserSelectFieldOption,
                                      }}
                                    />
                                  </PopoverBody>
                                </PopoverContent>
                              </>
                            ) : (
                              <>
                                <PopoverTrigger>
                                  <Box tabIndex="0" role="button" aria-label="Some box">
                                    <Tooltip hasArrow label="Assign Task" bg="black" placement="bottom">
                                      <Box position="relative">
                                        <i
                                          className="fa fa-user-circle"
                                          style={{
                                            fontSize: '30px',
                                          }}
                                        />
                                        <Badge
                                          variant="solid"
                                          width="14px"
                                          height="14px"
                                          borderRadius="14px"
                                          colorScheme="actionSecondary"
                                          position="absolute"
                                          right={0}
                                          bottom={0}
                                          padding={0}
                                        >
                                          <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
                                            <MdAdd boxSize="8.4px" />
                                          </Flex>
                                        </Badge>
                                      </Box>
                                    </Tooltip>
                                  </Box>
                                </PopoverTrigger>
                                <PopoverContent width="176px">
                                  <PopoverBody padding={0}>
                                    <SelectField
                                      placeholder="Search"
                                      options={userOptions}
                                      onChange={selectedOption => handleOptionSelect(exception.id, selectedOption)}
                                      containerWidth="100%"
                                      asHorizontalStack
                                      containerStackProps={{
                                        align: 'center',
                                      }}
                                      controlStyles={{
                                        borderRadius: '0px',
                                      }}
                                      menuStyles={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        borderRadius: '0px',
                                      }}
                                      placeholderStyles={{ marginLeft: '20px' }}
                                      singleValueStyles={{ marginLeft: '20px' }}
                                      components={{
                                        DropdownIndicator: () => null,
                                        ValueContainer: UserSelectFieldValueContainer,
                                        Option: UserSelectFieldOption,
                                      }}
                                    />
                                  </PopoverBody>
                                </PopoverContent>
                              </>
                            )}
                          </PopoverRoot>
                        )}
                      </Flex>
                    );
                  })}
                </VStack>
              </Table.Cell>
              <Table.Cell paddingX={0} textAlign="center" verticalAlign="center">
                <VStack
                  align="stretch"
                  alignItems="center"
                  separator={<Separator borderWidth="1.5px" style={{ margin: '10px 0' }} />}
                >
                  {exceptions.map(exception => {
                    return (
                      <Flex key={exception.id} alignItems="center" height="43px">
                        {userResolveState.isLoading && userResolveState.exceptionId === exception.id ? (
                          <Spinner cursor="pointer" />
                        ) : (
                          <ResolutionButton
                            isResolved={exception.status === 'CLOSED' && exception.is_flagged}
                            onClick={() => {
                              dispatchResolve({ type: 'loading', payload: exception.id });
                              onResolveClick(exception.id, {
                                is_flagged: !exception.is_flagged,
                                status: exception.status === 'CLOSED' ? 'OPEN' : 'CLOSED',
                              }).finally(() => {
                                dispatchResolve({ type: 'done' });
                              });
                            }}
                          />
                        )}
                      </Flex>
                    );
                  })}
                </VStack>
              </Table.Cell>
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan={7}>
            <Flex width="100%" height="200px" justifyContent="center" alignItems="center">
              <Text>No Daily Tasks found</Text>
            </Flex>
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};
TableBodyContent.propTypes = {
  allPurchaseOrders: PropTypes.shape({
    purchase_orders: PropTypes.arrayOf(
      PropTypes.shape({
        purchase_order: PropTypes.shape({
          id: PropTypes.string.isRequired,
          internal_po_number: PropTypes.string,
          grinder_po_number: PropTypes.string,
          exceptions: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              exception_type: PropTypes.shape({
                category: PropTypes.string,
                label: PropTypes.string,
              }),
              assigned_user: PropTypes.shape({
                id: PropTypes.string,
                first_name: PropTypes.string,
                last_name: PropTypes.string,
              }),
              status: PropTypes.string,
              is_flagged: PropTypes.bool,
            })
          ),
        }),
        grinder_name: PropTypes.string,
        packer_name: PropTypes.string,
      })
    ),
  }).isRequired,
  onResolveClick: PropTypes.func.isRequired,
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    })
  ).isRequired,
  onAssign: PropTypes.func.isRequired,
  token: PropTypes.string,
};

export default TableBodyContent;
