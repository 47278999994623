import React, { Component } from 'react';
import { Box, Text, Button as ChakraButton, List, VStack, Center } from '@chakra-ui/react';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fetchGrinders } from '../../../actions/actions_grinders';
import {
  bulkOrders,
  getColdStoreReleasePDF,
  resetColdStoreEmailModal,
  saveOrder,
  sendEmailWithReleasePDF,
  removeOrderFromScreen,
} from '../../../actions/actions_coldstore_orders_util';
import { fetchOrdersPendingColdstoreSchedule } from '../../../actions/actions_orders_pending_coldstore_schedule';
import { fetchOrdersPendingColdstoreRelease } from '../../../actions/actions_orders_pending_coldstore_release';
import { fetchOrdersColdstoreReleased } from '../../../actions/actions_orders_coldstore_released';
import { fetchTransporters } from '../../../actions/actions_transporters';
import Constants from '../../../Constants';
import Loading from '../../basic/Loading';
import { groupBy, isOrderCompleted } from '../helpers';
import GrinderRelease from './GrinderRelease';
import GrinderSchedule from './GrinderSchedule';
import './ReleaseTemplate.scss';
import { ReleasableOrderContext } from './contexts';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import StyledAlert from './StyledAlert';
import { getTenantFromDomain } from '../../../helpers/tenant';
import {
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from '@/components/ui/dialog';
import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from '../../ui/accordion';
import { Tooltip } from '@/components/ui/tooltip';
import { toaster } from '@/components/ui/toaster';

class ReleaseTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      lastUpdate: null,
      sentEmailsForOrdersIds: [],
      showConfirmModal: false,
      partiallyFilledOrders: [],
      releasableOrders: [],
      toTab: null,
      coldstoreStatus: null,
      fetchOrders: false,
      showAlert: true,
    };
  }

  componentDidMount() {
    const { dispatch, token, grinders, transporters } = this.props;
    window.scrollTo(0, 0);
    this.reloadOrders();
    if (grinders?.length === 0) {
      dispatch(fetchGrinders(token));
    }
    if (transporters?.length === 0) {
      dispatch(fetchTransporters(token));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchOrders } = this.state;
    if (fetchOrders && fetchOrders !== prevState.fetchOrders) {
      this.reloadOrders();
    }
  }

  // just to dry up the action dispatch calls
  fetchColdstoreOrdersByFn(fn, deliveryDateStart) {
    const { dispatch, token } = this.props;

    dispatch(fn(token, deliveryDateStart)).then(() => {
      this.setState({
        loading: false,
        lastUpdate: moment().format('DD-MM-YYYY h:mm A'),
      });
    });
  }

  reloadOrders = () => {
    this.setState({ loading: true });
    const { coldstore_status: coldstoreStatus, dispatch } = this.props;

    const status = coldstoreStatus;
    const deliveryDateStart = moment().startOf('week').format('YYYY-MM-DD');

    if (status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE) {
      this.fetchColdstoreOrdersByFn(fetchOrdersPendingColdstoreSchedule);
    } else if (status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_RELEASE) {
      this.fetchColdstoreOrdersByFn(fetchOrdersPendingColdstoreRelease);
    } else if (status === Constants.ORDER_STATUSES.COLDSTORE_RELEASED) {
      this.fetchColdstoreOrdersByFn(fetchOrdersColdstoreReleased, deliveryDateStart);
    }

    dispatch(resetColdStoreEmailModal());
  };

  // function for setting releasable orders
  updateScheduleRelease = (ordersMap, coldstoreStatus, toTab) => {
    let releasableOrders = [];
    let partiallyFilledOrders = [];

    // if any of the fields are not empty, partiallyFilledOrders is empty
    const selectedOrders = Object.values(cloneDeep(ordersMap));

    selectedOrders.forEach(iteratorOrder => {
      if (isOrderCompleted(iteratorOrder)) {
        // remove null_delivery_time as its's only for UI convenience, update status
        // remove fmg_po_number as it is not a patchable field
        iteratorOrder.status = coldstoreStatus;
        delete iteratorOrder.null_delivery_time;
        delete iteratorOrder.internal_po_number;
        // add to releasableOrders if not exists
        if (!releasableOrders.find(o => o.id === iteratorOrder.id)) {
          releasableOrders = [...releasableOrders, iteratorOrder];
        }
      } else {
        partiallyFilledOrders = [...partiallyFilledOrders, iteratorOrder];
      }
    });

    if (releasableOrders.length === 0) {
      toaster.create({
        title: 'Please select releaseable load(s) to proceed.',
        type: 'warning',
      });
    }
    if (selectedOrders.length === 0) {
      toaster.create({
        title: 'Please select load(s) to proceed.',
        type: 'warning',
      });
    } else if (releasableOrders.length > 0) {
      const confirmButtonText = toTab === 'release' ? 'Schedule release(s)' : 'Release Schedules';
      // confirm pop up
      this.setState({
        showConfirmModal: true,
        confirmButtonText,
        partiallyFilledOrders,
        toTab,
        coldstoreStatus,
        releasableOrders,
      });
    } else {
      this.setState({ partiallyFilledOrders });
    }
  };

  // backend
  confirmScheduleRelease = () => {
    const { releasableOrders, toTab, coldstoreStatus } = this.state;
    const { dispatch, token, handleNavigation } = this.props;
    // saves data to db and procees to next tab 'Release' or 'Schedule' if fully populated
    if (releasableOrders.length) {
      const safeOrders = releasableOrders.map(order => {
        const shallowCopy = { ...order };
        delete shallowCopy.active_price_bucket;
        return shallowCopy;
      });
      dispatch(bulkOrders(safeOrders, coldstoreStatus, token)).then(() => {
        this.onExit();
        this.setState({ fetchOrders: true });
        handleNavigation('release_management', toTab);
      });
    }
  };

  onExit = () => {
    this.setState({ showConfirmModal: false });
  };

  // released tab
  clearColdStoreEmailModal = () => {
    this.props.dispatch(resetColdStoreEmailModal());
  };

  emailColdstoreRelease = async (orders, subject, body, emails) => {
    const payload = {
      purchase_order_ids: orders.map(order => order.id),
      emails,
      email_subject: subject,
      email_body: body,
      status: Constants.ORDER_STATUSES.COLDSTORE_RELEASED,
      pdfTitle: { title: 'Delivery Schedule', fontColour: 'black' },
    };
    await this.props.dispatch(sendEmailWithReleasePDF(payload, orders, this.props.token));
    this.props.handleNavigation('release', 'released');
  };

  fetchColdStoreReleasePDF = orders => {
    const purchaseOrderIds = orders.map(o => o.id);
    const payload = {
      purchase_order_ids: purchaseOrderIds,
      pdfTitle: { title: 'Delivery Schedule', fontColour: 'black' },
    };
    this.props.dispatch(getColdStoreReleasePDF(payload, this.props.token));
  };

  // in release and released tab or deleting individual rows
  patchOrder = (id, payload, moveToPrevTab = false) => {
    const { orders, dispatch, token, handleNavigation } = this.props;
    const currentOrder = orders.find(order => order.id === id);

    if (moveToPrevTab !== false) {
      if (currentOrder.status !== payload.status) {
        dispatch(removeOrderFromScreen(currentOrder, payload.status));
      }
      dispatch(saveOrder(payload, id, token));
      handleNavigation('release_management', moveToPrevTab);
    } else {
      if (currentOrder.status !== payload.status) {
        dispatch(removeOrderFromScreen(currentOrder, payload.status));
      }
      return dispatch(saveOrder(payload, id, token));
    }

    return null;
  };

  renderConfirmModalContent = () => {
    const { releasableOrders } = this.state;
    const tenant = getTenantFromDomain();
    const domain = tenant.includes('cfc') ? 'CFCO' : 'FMG';
    return (
      <Box>
        <Text as="p" fontWeight="bold">
          The following PO&apos;s will be scheduled:
        </Text>
        <List.Root styleType="none" margin="0px">
          {releasableOrders.map(orderIterator => {
            const orderItem = this.props.orders?.filter(o => o?.id === orderIterator.id);
            if (!orderItem || orderItem.length === 0) {
              return null;
            }
            return (
              <List.Item>
                {domain} PO# {orderItem[0]?.internal_po_number || ''} / Grinder PO#{' '}
                {orderItem[0]?.grinder_po_number || ''}
              </List.Item>
            );
          })}
        </List.Root>
        <Center height="136px">
          <Text textAlign="center" fontWeight="bold">
            Are you sure you want to continue?
          </Text>
        </Center>
      </Box>
    );
  };

  // grouped grinder orders
  renderGrinderOrders = (grinderUID, orders) => {
    // sort by delivery date by default
    const orderedOrders = orderBy(orders, 'delivery_date');
    const grinder = this.props.grinders.find(grndr => grndr.uid === grinderUID);
    const releasedCount = orderedOrders.filter(o => o.status === Constants.ORDER_STATUSES.COLDSTORE_RELEASED).length;
    return (
      <AccordionItem
        key={grinderUID}
        value={grinderUID}
        borderTopWidth="0px"
        borderBottomWidth="1px"
        paddingY="11px"
        _last={{
          borderBottomWidth: '0px',
        }}
      >
        <AccordionItemTrigger paddingY="8.5px" paddingRight="15px">
          <Tooltip
            content={releasedCount === 0 && `${orderedOrders.length} load(s) with detail to update/email`}
            positioning={{ placement: 'right-end' }}
          >
            <Text as="p" marginLeft="14px" >
              {grinder?.name}
            </Text>
          </Tooltip>
        </AccordionItemTrigger>

        <AccordionItemContent padding="0px 10px">
          {this.props.coldstore_status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE ? (
            <ReleasableOrderContext.Provider value={this.state.releasableOrders}>
              <GrinderSchedule
                orders={orderedOrders}
                transporters={this.props.transporters}
                grinders={this.props.grinders}
                patchOrder={this.patchOrder}
                scheduleRelease={this.updateScheduleRelease}
                token={this.props.token}
                user={this.props.user}
                dispatch={this.props.dispatch}
                internalPoLabel={this.props.internalPoLabel}
              />
            </ReleasableOrderContext.Provider>
          ) : (
            <GrinderRelease
              orders={orderedOrders}
              transporters={this.props.transporters}
              grinders={this.props.grinders}
              patchOrder={this.patchOrder}
              onSendEmails={this.emailColdstoreRelease}
              sentEmailsForOrdersIds={this.state.sentEmailsForOrdersIds}
              previewOrders={this.props.previewOrders}
              token={this.props.token}
              user={this.props.user}
              dispatch={this.props.dispatch}
              status={this.props.coldstore_status}
              release={this.updateScheduleRelease}
              getColdStoreReleasePDF={this.fetchColdStoreReleasePDF}
              notifying_action_groups={this.props.notifying_action_groups}
              coldstoreSendEmailError={this.props.coldstoreSendEmailError}
              clearColdStoreEmailModal={this.clearColdStoreEmailModal}
              isColdstoreEmailSent={this.props.isColdstoreEmailSent}
              internalPoLabel={this.props.internalPoLabel}
            />
          )}
          <DialogRoot open={this.state.showConfirmModal} onClose={this.onExit} size="lg" centered>
            <DialogContent maxWidth="860px">
              <DialogHeader borderBottom="1px" borderColor="gray.200" paddingLeft="48px" marginBottom="17px">
                <Text as="p" fontSize="20px" fontWeight="bold">
                  Confirm Your Selection
                </Text>
                <DialogCloseTrigger asChild onClick={this.onExit} marginTop="8px" />
              </DialogHeader>
              <DialogBody paddingX="52px">{this.renderConfirmModalContent()}</DialogBody>
              <DialogFooter borderTopWidth="1px" marginTop="48px">
                <ChakraButton
                  colorScheme="actionPrimary"
                  height="40px"
                  width="112px"
                  fontSize="16px"
                  onClick={() => {
                    this.confirmScheduleRelease();
                  }}
                >
                  Confirm
                </ChakraButton>
              </DialogFooter>
            </DialogContent>
          </DialogRoot>
        </AccordionItemContent>
      </AccordionItem>
    );
  };

  // render data
  render() {
    const { showAlert, loading } = this.state;
    const { title, orders, noDataHeaderMessage, headerMessage, infoMessage } = this.props;
    const groupedOrders = groupBy(orders, 'grinder_uid');

    return (
      <VStack align="stretch" gap="47px" padding="47px">
        <DashboardSectionTile title={title} paddingX="36px">
          {loading ? (
            <Center>
              <Loading />
            </Center>
          ) : (
            <VStack align="stretch" gap="38px">
              {isEmpty(groupedOrders) && showAlert && (
                <StyledAlert
                  status="error"
                  infoMessage={isEmpty(groupedOrders) ? noDataHeaderMessage : headerMessage}
                  onClick={() => this.setState({ showAlert: !showAlert })}
                />
              )}
              {infoMessage && !isEmpty(groupedOrders) && showAlert && (
                <StyledAlert
                  status="info"
                  infoMessage={infoMessage}
                  onClick={() => this.setState({ showAlert: !showAlert })}
                />
              )}
              <AccordionRoot
                collapsible
                borderRadius="20px"
                boxShadow="0px 4px 20px 0px #0000001A"
                borderTopWidth="0px"
                backgroundColor="white"
              >
                {Object.entries(groupedOrders).map(([grinderUID, ordrs]) => {
                  return this.renderGrinderOrders(grinderUID, ordrs);
                })}
              </AccordionRoot>
            </VStack>
          )}
        </DashboardSectionTile>
      </VStack>
    );
  }
}

export default ReleaseTemplate;

ReleaseTemplate.propTypes = {
  dispatch: PropTypes.func.isRequired,
  token: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  grinders: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  transporters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  coldstore_status: PropTypes.string,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      grinder_uid: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      delivery_date: PropTypes.string.isRequired,
      internal_po_number: PropTypes.string,
      grinder_po_number: PropTypes.string,
    })
  ),
  handleNavigation: PropTypes.func,
  previewOrders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      grinder_uid: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      delivery_date: PropTypes.string.isRequired,
      internal_po_number: PropTypes.string,
      grinder_po_number: PropTypes.string,
    })
  ),
  notifying_action_groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  coldstoreSendEmailError: PropTypes.string,
  isColdstoreEmailSent: PropTypes.bool,
  internalPoLabel: PropTypes.string,
  noDataHeaderMessage: PropTypes.string,
  headerMessage: PropTypes.string,
  infoMessage: PropTypes.string,
  title: PropTypes.string,
};
