import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Box } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

const iconLookup = {
  check: FaCheck,
  close: MdClose,
  default: FaCheck,
};

const DEFAULT_PROPS = {
  circleSize: 107,
  boxSize: 7,
  iconTopPosition: '65px',
};

const CircleIcon = ({ icon, iconColor, circleSize, iconSize, iconTopPosition, ...rest }) => {
  const CustomIcon = iconLookup[icon] || iconLookup.default;
  return (
    <Box>
      {/* TODO -- Figure out a better way in the future to show icon above SVG icon */}
      <Box alignItems="center" display="float">
        <CustomIcon
          position="relative"
          left="0%"
          top={iconTopPosition || DEFAULT_PROPS.iconTopPosition}
          color={iconColor || 'white'}
          boxSize={iconSize || DEFAULT_PROPS.iconSize}
        />
      </Box>
      <Icon viewBox="0 0 200 200" boxSize={circleSize || DEFAULT_PROPS.circleSize} {...rest}>
        <path fill="currentColor" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />
      </Icon>
    </Box>
  );
};

CircleIcon.propTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  circleSize: PropTypes.number,
  iconSize: PropTypes.number,
  iconTopPosition: PropTypes.number,
};

export default CircleIcon;
